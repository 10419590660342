import React, { useEffect } from 'react';
import {withStateHandlers, compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Switch, Route, withRouter } from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import About from '../About/About';
import ApplicationStyle from './ApplicationStyle';
import AppBar from '../../components/block/AppBar/AppBar';
import Login from '../../components/admin/containers/Login/Login';
import AdminDashboard from '../../components/admin/containers/Dashboard/Dashboard'
import Users from '../../components/admin/containers/Users/Users'
import AdminAppBar from '../../components/admin/components/AdminAppBar/AdminAppBar';
import Sidebar from '../../components/admin/components/SideBar/Sidebar';
import AdminProfile from '../../components/admin/containers/AdminProfile/AdminProfile';
import SignIn from "../../components/block/SignIn/SignIn";
import SignUp from "../../components/block/SignUp/SignUp";
import Footer from '../../components/block/Footer/Footer';
import LiveChat from "../../components/block/LiveChat/LiveChat";
import GlobalPreloader from "../../components/admin/ui/GlobalPreloader/GlobalPreloader";

interface ApplicationProps {
  readonly classes: any;
  readonly appbar: any;
  setAppbar: any;
  history: any;
}

const defaultAppbar = <AppBar />;
const adminAppbar = <><AdminAppBar /><Sidebar /></>;
const noneAppbar = <></>;

const Application: React.FC<ApplicationProps> = ({ classes, appbar, setAppbar, history }) => {
  useEffect(() => {
    if (history.location.pathname.indexOf('/admin') === -1 && appbar !== defaultAppbar) {
      setAppbar(defaultAppbar);
    } else if (history.location.pathname.indexOf('/admin/') !== -1 && appbar !== adminAppbar) {
      setAppbar(adminAppbar);
    } else if (history.location.pathname.indexOf('/admin') !== -1 && history.location.pathname.indexOf('/admin/') === -1 && appbar !== noneAppbar) {
      setAppbar(noneAppbar);
    }
  });

  return (
    <div className={classes.root}>
      {appbar}
      <Switch>
        <Route path="/" component={Dashboard} exact />
        <Route path="/about" component={About} />
        <Route path="/admin" component={Login} exact />
        <Route path="/admin/dashboard" component={AdminDashboard} />
        <Route path="/admin/profile" component={AdminProfile} />
        <Route path="/admin/users" component={Users} />
      </Switch>
      {history.location.pathname.indexOf('/admin') === -1 ?
        <>
          <Footer />
          <SignIn />
          <SignUp />
        </>: false}
      <LiveChat />
      <GlobalPreloader />
    </div>
  );
};

export default compose<ApplicationProps, {}>(
  withStateHandlers(
    {
      appbar: defaultAppbar
    },
    {
      setAppbar: () => (value: any) => ({
        appbar: value
      })
    }
  ),
  withStyles(ApplicationStyle, {
    name: 'Application'
  }),
  withRouter,
)(Application);


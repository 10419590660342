import { createAction } from 'redux-act';
import types from './types';

const userActions = Object.freeze({
  setUserInfo: (data: any) => ({
    type: types.SET_USER_INFO,
    payload: data
  }),
  setSignUpPreloader: (status: boolean) => ({
    type: types.SET_SIGN_UP_PRELOADER,
    payload: status
  }),
  setSignInPreloader: (status: boolean) => ({
    type: types.SET_SIGN_IN_PRELOADER,
    payload: status
  }),
  setFormError: (text: string) => ({
    type: types.SET_FORM_ERROR,
    payload: text
  }),
  setListOfUsers: (data: any) => ({
    type: types.SET_LIST_OF_USERS,
    payload: data
  })
});

export const setRestartListenServer = createAction(
  types.RESTART_LISTEN_SERVER,
  status => status
);

export default userActions;

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import ProductsStyle from './ProductsStyle';
import Product from "../../ui/Product/Product";
import ShowerImg from './img/shower.jpg';
import ShowerSchemeImg from './img/shower_scheme.jpg';

interface ProductsProps {
  classes: any;
}

const Products: React.FC<ProductsProps> = ({ classes }) => {
  const cards = [
    {
      id: 0,
      categoryTitle: 'Премиум душевые кабины',
      title: 'Раздвижные душевые кабины',
      description: 'Душа моя озарена неземной радостью, как эти чудесные весенние утра, которыми я наслаждаюсь от всего сердца.',
      img: [ShowerSchemeImg, ShowerImg],
    },
    {
      id: 1,
      categoryTitle: 'Премиум душевые кабины',
      title: 'Распашные душевые кабины',
      description: 'Душа моя озарена неземной радостью, как эти чудесные весенние утра, которыми я наслаждаюсь от всего сердца.',
      img: [ShowerSchemeImg, ShowerImg],
    },
    {
      id: 2,
      categoryTitle: 'Премиум душевые кабины',
      title: 'Душевые перегородки',
      description: 'Душа моя озарена неземной радостью, как эти чудесные весенние утра, которыми я наслаждаюсь от всего сердца.',
      img: [ShowerSchemeImg, ShowerImg],
    },
    {
      id: 3,
      categoryTitle: 'Премиум душевые кабины',
      title: 'Душевые перегородки',
      description: 'Душа моя озарена неземной радостью, как эти чудесные весенние утра, которыми я наслаждаюсь от всего сердца.',
      img: [ShowerSchemeImg, ShowerImg],
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const addToFavorite = (id: number) => {};

  return (
    <Container fixed className={classes.container}>
      <h2>Популярные товары</h2>
      <Grid container spacing={2} className={classes.products}>
        {cards.map(item => <Product
          card={item}
          addToFavorite={addToFavorite}
          key={item.id} />)}
      </Grid>
    </Container>
  )
};

export default withStyles(ProductsStyle, {
  name: 'Products',
})(Products);
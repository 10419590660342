import * as React from 'react';
// import { DataGrid } from '@material-ui/data-grid';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';


interface TableDataUsersProps {
  classes: any;
  rows: any;
  onRowEditSave: (event: any) => void;
  onRowEditInit: (event: any) => void;
  onRowEditCancel: (event: any) => void;
  onEditorValueChange: (props: any, value: any) => void;
}

const TableDataUsers: React.FC<TableDataUsersProps> = ({
  classes,
  rows,
  onRowEditInit,
  onRowEditCancel,
  onEditorValueChange,
  onRowEditSave
}) => {
  const roles = [
    { label: 'User', value: 'user' },
    { label: 'Admin', value: 'admin' },
  ];

  const appAccess = [
    { label: 'Нет', value: 0 },
    { label: 'Да', value: 1 },
  ];

  const columns: any = [
    { field: 'id', headerName: 'ID', width: 60 },
    { field: 'firstName', headerName: 'Имя', width: 110 },
    { field: 'lastName', headerName: 'Фамилия', width: 120 },
    { field: 'role', headerName: 'Роль', width: 90 },
    { field: 'email', headerName: 'Эл. Адрес', width: 180 },
    { field: 'phone', headerName: 'Номер телефона', width: 150 },
    { field: 'app', headerName: 'Доступ к приложению', width: 120 },
    { field: 'createdAt', headerName: 'Дата регистрации', width: 150 },
    { field: 'updatedAt', headerName: 'Дата обновления данных', width: 150 }
  ];

  const getRoleLabel = (status: string) => {
    switch (status) {
      case 'user':
        return 'User';
      case 'admin':
        return 'Admin';
      default:
        return 'User';
    }
  };

  const getAppLabel = (status: number) => {
    switch (status) {
      case 0:
        return 'Нет';
      case 1:
        return 'Да';
      default:
        return 'Нет';
    }
  };

  const statusEditor = (field: string, width: string, props: any) => {
    return (
      <Dropdown value={field === 'role' ? props.rowData.role : props.rowData.app} options={field === 'role' ? roles : appAccess} optionLabel="label" optionValue="value"
        onChange={(e) => onEditorValueChange(props, e.value)} style={{ width: '100%' }} placeholder="Select a Status"
        itemTemplate={(option) => {
          return <span className={`product-badge status-${Number.isNaN(option.value) && option.value.toLowerCase()}`}>{option.label}</span>
        }} />
    );
  };

  const inputTextEditor = (field: string, width: string, props: any) => {
    return <InputText type="text" style={{ width }} value={props.rowData[field]} onChange={(e: any) => onEditorValueChange(props, e.target.value)} />;
  };

  const roleBodyTemplate = (rowData: any) => {
    return getRoleLabel(rowData.role);
  };

  const appBodyTemplate = (rowData: any) => {
    return getAppLabel(rowData.app);
  };

  const handleEditor = (field: string, width: string, props: any) => {
    return field !== 'id' && field !== 'createdAt' && field !== 'updatedAt' ? inputTextEditor(field, width, props) : props.rowData[field];
  };

  return (
    <DataTable
      value={rows}
      editMode="row" 
      dataKey="id"
      className={classes.table}
      onRowEditSave={onRowEditSave}
      onRowEditInit={onRowEditInit}
      onRowEditCancel={onRowEditCancel}>
      {columns.map((column: any) => <Column
        field={column.field}
        header={column.headerName}
        headerStyle={{ width: column.width }}
        body={column.field === 'role' ? roleBodyTemplate : column.field === 'app' ? appBodyTemplate : false}
        editor={(props) => column.field === 'role' || column.field === 'app' ?
          statusEditor(column.field, column.width, props) :
          handleEditor(column.field, column.width, props)}
        key={column.field}
      />)}
      <Column rowEditor headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'center' }} />
    </DataTable>
  );
};

export default TableDataUsers;
import axios from 'axios';
import { constants } from './constants';

const api: any = {
  user: {
    signUp(userData: any): object {
      const urlencoded = new URLSearchParams();
      urlencoded.append('firstname', userData.firstname);
      urlencoded.append('lastname', userData.lastname);
      urlencoded.append('email', userData.email);
      urlencoded.append('password', userData.password);
      return new Promise(resolve => {
        axios.request<object>({
          method: 'POST',
          url: `${constants.apiUrl}/reg`,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: urlencoded
        }).then(res => {
          resolve(res);
        }).catch(err => {
          resolve(err.response);
        });
      });
    },
    signIn(tokenBase64: string): object {
      return axios.request<object>({
        method: 'POST',
        url: `${constants.apiUrl}/auth/user/login`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${tokenBase64}`,
        }
      }).then(res => res).catch(err => err.response);
    },
    updateToken(token: string): object {
      return axios.request<object>({
        method: 'POST',
        url: `${constants.apiUrl}/update/token`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: { token }
      }).then(res => res).catch(err => err.response);
    },
    getListOfUsers(token: string): object {
      return axios.request<object>({
        method: 'GET',
        url: `${constants.apiUrl}/get/users`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      }).then(res => res).catch(err => err.response);
    },
    updateUser(data: object, token: string): object {
      return axios.request<object>({
        method: 'POST',
        url: `${constants.apiUrl}/update/user`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        data
      }).then(res => res).catch(err => err.response);
    }
  }
};

export default api;

import { Map, List, set } from 'immutable';
import types from './types';

export interface UserState extends Map<string, any> {
  readonly userInfo?: any;
  readonly signUpPreloader?: boolean;
  readonly setSignInPreloader?: boolean;
  readonly errorText?: string;
  readonly restartListenServer?: boolean;
  readonly listOfUsers?: any;
}

const initialState: UserState = Map({
  userInfo: localStorage.getItem('userInfo') !== null ? JSON.parse(localStorage.getItem('userInfo') as string) : {
    email: '',
    firstname: '',
    lastname: '',
    token: '',
  },
  signUpPreloader: false,
  signInPreloader: false,
  errorText: '',
  restartListenServer: false,
  listOfUsers: List([]),
});

export const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.SET_USER_INFO:
      return set(state, 'userInfo', action.payload);
    case types.SET_SIGN_UP_PRELOADER:
      return set(state, 'signUpPreloader', action.payload);
    case types.SET_SIGN_IN_PRELOADER:
      return set(state, 'signInPreloader', action.payload);
    case types.SET_FORM_ERROR:
      return set(state, 'errorText', action.payload);
    case types.SET_LIST_OF_USERS:
      return set(state, 'listOfUsers', action.payload);
    case types.RESTART_LISTEN_SERVER:
      return state.set('restartListenServer', action.payload);
    default:
      return state;
  }
};

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
// import { Container } from '@material-ui/core';
import DashboardStyle from './DashboardStyle';
import MainSlider from '../../components/block/MainSlider/MainSlider';
import Categories from '../../components/block/Categories/Categories';
import Products from "../../components/block/Products/Products";


interface DashboardProps {
  readonly classes: any;
}

const Dashboard: React.FC<DashboardProps> = ({ classes }) => {
  return (
    <main className={classes.content}>
      <MainSlider />
      <Categories />
      <Products />
    </main>
  );
};

export default  withStyles(DashboardStyle, {
  name: 'Dashboard'
})(Dashboard);

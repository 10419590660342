import React from 'react';
import {connect} from "react-redux";
import {Link, Redirect} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Avatar, IconButton, Button, Badge, MenuItem, Menu, Divider } from '@material-ui/core';
import {
  Menu as MenuIcon,
  Notifications as NotificationsIcon,
  Person as PersonIcon,
  Settings as SettingsIcon
} from '@material-ui/icons';
import Notification from '../Notification/Notification'
import AdminAppBarStyle from './AdminAppBarStyle';
import BadgeInfo from '../BadgeInfo/BadgeInfo';
import {RootState} from "../../../../../engine/init/rootReducer";
import userActionsAsync from "../../../../../engine/core/user/saga/asyncActions";


interface AdminAppBarProps {
  classes: any;
  userInfo: any;
  signOutAsync: () => void;
}

interface AdminAppBarState {
  anchorElNotification: null | HTMLElement;
  anchorElProfile: null | HTMLElement;
}

interface AdminAppBarDispatchToProps {
  signOutAsync: () => void;
}

const mapStateToProps = (state: RootState) => ({
  userInfo: state.user.get('userInfo'),
});

const mapDispatchToProps: AdminAppBarDispatchToProps = {
  signOutAsync: userActionsAsync.signOutAsync,
};

class AdminAppBar extends React.PureComponent<AdminAppBarProps, AdminAppBarState> {
  state = {
    anchorElNotification: null,
    anchorElProfile: null,
  };

  handleNotificationClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorElNotification: event.currentTarget });
  };

  handleProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorElProfile: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorElNotification: null });
    this.setState({ anchorElProfile: null });
  };

  handleSignOut = () => {
    this.props.signOutAsync();
  };

  render() {
    const { classes, userInfo } = this.props;
    const { anchorElNotification, anchorElProfile } = this.state;

    return userInfo !== null && userInfo.token && userInfo.role === 'admin' ? (
      <AppBar className={classes.header}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>

        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          className={classes.notificationIcon}
          onClick={this.handleNotificationClick}
        >
          <Badge badgeContent={3} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorElNotification}
          keepMounted
          open={Boolean(anchorElNotification)}
          onClose={this.handleClose}
          className={classes.notificationMenu}
        >
          <h4 className={classes.notificationTitle}>У вас новые уведомления</h4>
          <Divider />

          <div className={classes.notificationMenu}>
            <Notification
              title='Заголовок уведомления'
              description='У вас непрочитанное сообщение с чата'
            />
            <Notification
              title='Уведомление'
              description='У вас непрочитанное сообщение с чата'
            />
            <Notification
              title='Уведомление'
              description='У вас непрочитанное сообщение с чата'
            />
          </div>
        </Menu>

        <IconButton
          role='button'
          aria-controls="profile-menu"
          aria-haspopup="true"
          onClick={this.handleProfileClick}
        >
          <Avatar className={classes.avatar}>
            {userInfo.firstName.charAt(0)}
          </Avatar>
        </IconButton>
        <Menu
          id="profile-menu"
          anchorEl={anchorElProfile}
          keepMounted
          open={Boolean(anchorElProfile)}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className={classes.popUpProfile}>
            <BadgeInfo
              name={`${userInfo.firstName} ${userInfo.lastName}`}
              status={userInfo.email}
            />
            <Divider />
          </div>
          <Link to='/admin/profile'>
            <MenuItem className={classes.popUpLink}>
              <PersonIcon className={classes.popUpIcon} />
              <span>Профиль</span>
            </MenuItem>
          </Link>
          <Link to='/admin/settings'>
            <MenuItem className={classes.popUpLink}>
              <SettingsIcon className={classes.popUpIcon} />
              <span>Настройки</span>
            </MenuItem>
          </Link>

          <Button
            className={classes.buttonLogout}
            variant="outlined"
            onClick={this.handleSignOut}
          >
            Выйти
          </Button>

        </Menu>

      </AppBar>
    ) : <Redirect to="/" />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(AdminAppBarStyle, {
  name: 'AdminAppBar'
})(AdminAppBar)
);
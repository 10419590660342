import { createStyles } from '@material-ui/core/styles';
import jssVariables from '../../../../helpers/variables';

const CategoryStyle = (theme: any) =>
  createStyles({
    card: {
      display: 'block',
      opacity: 0.6,
      position: 'relative',
      width: '100%',
      zIndex: 0,
      transition: 'opacity .3s',
      [theme.breakpoints.up('md')]: {
        '&:hover': {
          zIndex: 1,
        },
        '&:hover $cardImg': {
          transform: 'translateY(-45%) scale(0.75)',
          boxShadow: '0 0 20px 0 rgba(0,0,0,.35)',
        },
        '&:hover $cardHidden': {
          opacity: 1,
          visibility: 'visible',
          '&::before': {
            transform: 'scaleY(1.1) scaleX(1.20)',
          }
        },
        '&:hover $cardHiddenTitle': {
          opacity: 1,
          visibility: 'visible',
          transform: 'translateY(0)',
        },
        '&:hover $cardHiddenButton': {
          opacity: 1,
          visibility: 'visible',
          transform: 'translateY(0)',
        },
        '&:hover $cardHiddenPrice': {
          opacity: 1,
          visibility: 'visible',
          transform: 'translateY(0)',
        },
        '&:hover $cardHiddenShowers': {
          opacity: 1,
          visibility: 'visible',
          transform: 'translateY(0)',
        },
      },
    },
    cardOpacity: {
      opacity: 1,
    },
    cardImg: {
      position: 'relative',
      width: '100%',
      height: 370,
      overflow: 'hidden',
      borderRadius: 7,
      zIndex: 3,
      transition: 'transform .2s ease, box-shadow .2s',
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      }
    },
    cardBottom: {
      paddingTop: 10,
    },
    cardBottomTitle: {
      color: jssVariables.textColor,
      fontSize: 18,
      fontWeight: 700,
      width: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      marginBottom: 5,
    },
    cardBottomRow: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      zIndex: 1,
    },
    cardBottomShowers: {
      color: '#000',
      fontSize: 14,
      '& span': {
        display: 'inline-block',
        marginLeft: 5,
        fontSize: 12,
        fontWeight: 600,
      },
      '&:not(:last-of-type)': {
        marginRight: 10,
      }
    },
    cardHidden: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: 0,
      visibility: 'hidden',
      zIndex: 2,
      transition: 'opacity .3s, visibility .3s',
      '&::before': {
        content: "''",
        position: 'absolute',
        top: 15,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: 7,
        backgroundColor: jssVariables.secondColor,
        boxShadow: 'inset 0 -200px 140px -120px rgb(0 102 255 / 20%)',
        transition: 'transform .2s',
      }
    },
    cardHiddenTitle: {
      marginTop: '65%',
      textAlign: 'center',
      opacity: 0,
      visibility: 'hidden',
      whiteSpace: 'pre-wrap',
      fontSize: 26,
      lineHeight: 1.1,
      transform: 'translateY(50px)',
      transition: 'transform .3s, opacity .3s, visibility .3s',
      transitionDelay: '.05s',
    },
    cardHiddenButton: {
      opacity: 0,
      visibility: 'hidden',
      fontSize: 10,
      height: 35,
      transform: 'translateY(50px)',
      transition: 'transform .3s, opacity .3s, visibility .3s',
      transitionDelay: '.2s',
    },
    cardHiddenPrice: {
      fontSize: 24,
      fontWeight: 700,
      color: '#23bf20',
      backgroundColor: 'rgba(35, 191, 32, 0.1)',
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: '3px 25px',
      borderRadius: 50,
      textAlign: 'center',
      opacity: 0,
      visibility: 'hidden',
      transform: 'translateY(50px)',
      transition: 'transform .3s, opacity .3s, visibility .3s',
      transitionDelay: '.1s',
    },
    cardHiddenShowers: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      opacity: 0,
      visibility: 'hidden',
      fontSize: 14,
      textAlign: 'center',
      transform: 'translateY(50px)',
      transition: 'transform .3s, opacity .3s, visibility .3s',
      transitionDelay: '.15s',
      '& span': {
        color: '#000',
        fontWeight: 700,
        fontSize: 18
      },
      '& p': {
        color: jssVariables.greyColor,
        lineHeight: 1,
      },
    },
  });

export default CategoryStyle;

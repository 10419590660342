import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF, faViber } from '@fortawesome/free-brands-svg-icons';
import FooterStyle from "./FooterStyle";

interface FooterProps {
  classes: any;
}

const Footer: React.FC<FooterProps> = ({ classes }) => {
  return (
    <div className={classes.root}>
      <Container>
        <Grid container alignItems="flex-start">
          <Grid item xs={12} md={3}>
            <Link to="/" className={classes.logo}>Danavis</Link>
            <p className={classes.copyright}>© 2021 Danavis Все права защищены.</p>
            <div className={classes.social}>
              <FontAwesomeIcon icon={faInstagram} />
              <FontAwesomeIcon icon={faFacebookF} />
              <FontAwesomeIcon icon={faViber} />
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <p className={classes.subtitle}>Контакты</p>
            <p className={classes.text}>тел. +38 096 736 52 42</p>
            <p className={classes.text}>тел. +38 099 003 90 73</p>
          </Grid>
          <Grid item xs={12} md={3}>
            <p className={classes.subtitle}>Режим работы</p>
            <p className={classes.text}>ПН – ПТ: с 9:00 до 18:00</p>
            <p className={classes.text}>СБ: вихідний</p>
            <p className={classes.text}>НД: вихідний</p>
          </Grid>
          <Grid item xs={12} md={3}>
            <p className={classes.subtitle}>Контакты</p>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default withStyles(FooterStyle, {
  name: 'Footer',
})(Footer);
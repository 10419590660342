import React from 'react';
import {connect} from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import {Redirect} from "react-router-dom";
import UsersStyle from './UsersStyle';
import {RootState} from "../../../../../engine/init/rootReducer";
import userActionsAsync from "../../../../../engine/core/user/saga/asyncActions";
import TableDataUsers from '../../components/TableData/TableDataUsers';
import TableDataHoc from "../../components/TableData/TableDataHoc";
import uiActions from "../../../../../engine/core/ui/action";

interface UsersProps {
  classes: any;
  userInfo: any;
  listOfUsers: any;
  getUsersAsync: (token: string) => void;
  updateUserAsync: (data: object) => void;
  setGlobalPreloaderStatus: (status: boolean) => void;
}

interface UsersDispatchToProps {
  getUsersAsync: (token: string) => void;
  updateUserAsync: (data: object) => void;
  setGlobalPreloaderStatus: (status: boolean) => void;
}

const mapStateToProps = (state: RootState) => ({
  userInfo: state.user.get('userInfo'),
  listOfUsers: state.user.get('listOfUsers'),
});

const mapDispatchToProps: UsersDispatchToProps = {
  getUsersAsync: userActionsAsync.getUsersAsync,
  updateUserAsync: userActionsAsync.updateUserAsync,
  setGlobalPreloaderStatus: uiActions.setGlobalPreloaderStatus,
};

class Users extends React.PureComponent<UsersProps> {
  componentDidMount(): void {
    const {userInfo, getUsersAsync, setGlobalPreloaderStatus} = this.props;
    if (userInfo !== null && userInfo.token && userInfo.role === 'admin') {
      setGlobalPreloaderStatus(true);
      getUsersAsync(userInfo.token);
    }
  }

  render() {
    const { classes, userInfo, listOfUsers, updateUserAsync, setGlobalPreloaderStatus } = this.props;

    const TableOfUsers = TableDataHoc(TableDataUsers, listOfUsers, updateUserAsync, setGlobalPreloaderStatus, classes);

    return userInfo !== null && userInfo.token && userInfo.role === 'admin' ? (
      <main className={classes.content}>
        <div className={classes.header}>
          <h1 className={classes.title}>Пользователи</h1>
        </div>
        <TableOfUsers />
      </main>
    ) : <Redirect to="/" />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(UsersStyle, {
  name: 'Users'
})(Users));

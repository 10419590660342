import {put, apply, StrictEffect} from 'redux-saga/effects';
import api from '../../../config/api';
import uiActions from "../../../ui/action";

export default function* callUpdateUserWorker({ payload: data }: any): Generator<StrictEffect> {
  const { token } = JSON.parse(localStorage.getItem('userInfo') as string);
  const response: any = yield apply(api, api.user.updateUser, [data, token]);
  if (response.status === 200 && response.statusText === 'OK') {
    yield put(uiActions.setGlobalPreloaderStatus(false));
  }
}

import React from 'react';
import autoscroll from 'autoscroll-react';

interface LiveChatScrollProps {
  classes: any;
  listMessages: string[];
  botMessagePreloaderStatus: boolean;
}

class LiveChatScroll extends React.PureComponent<LiveChatScrollProps> {
  render() {
    const { classes, listMessages, botMessagePreloaderStatus, ...props } = this.props;

    return (
      <ul className={classes.chatArea} {...props}>
        {listMessages.map((item: string) => <li className={classes.chatMessage} key={item}>{item}</li>)}
        {botMessagePreloaderStatus && (
          <li className={classes.chatAnim}>
            <span />
            <span />
            <span />
          </li>)}
      </ul>
    );
  }
}

export default autoscroll(LiveChatScroll);

import { createStyles } from '@material-ui/core';
import jssVariables from "../../../../../helpers/variables";


const NotificationStyle = (theme: any) =>
  createStyles({
    notificationWrapper: {
      fontFamily: 'inherit',
      borderLeft: '2px solid #fff',
      borderRight: '2px solid #fff',
      transition: 'all .3s ease',
      
      '&:hover': {
        backgroundColor: jssVariables.secondColor,
        borderLeft: '2px solid #00ab55',
        borderRight: '2px solid #00ab55',
        color: '#000',
        '& svg': {
          fill: '#000',
        },
      },
    },
    notification: {
      borderRadius: 5,
      padding: '10px 0',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      width: 300,
      color: '#555',

    },
    notificationText: {
      marginLeft: 10,
      textTransform: 'full-width',
      '& h5': {
        fontSize: 15,
      },
      '& p': {
        fontSize: 14,
        lineHeight: '1em',
      },
    }
  });

export default NotificationStyle;

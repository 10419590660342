import React from 'react';
import { Avatar, withStyles } from '@material-ui/core';
import BadgeInfoStyle from './BadgeInfoStyle';

interface BadgeInfoProps {
  classes: any,
  name?: string,
  status?: string,
}

const BadgeInfo: React.FC<BadgeInfoProps> = ({
  classes,
  name = 'User',
  status = 'admin'
}) => {
  return (
    <div className={classes.badge}>
      <Avatar className={classes.avatar} >
        {name[0]}
      </Avatar>
      <div className={classes.info}>
        <h5 className={classes.name}>{name}</h5>
        <p>{status}</p>
      </div>
    </div>
  )
}
export default withStyles(BadgeInfoStyle, {
  name: 'BadgeInfo'
})(BadgeInfo);

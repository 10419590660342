import { createStyles } from '@material-ui/core/styles';
import jssVariables from '../../../../helpers/variables';

const FooterStyle = (theme: any) =>
  createStyles({
    root: {
      width: '100%',
      paddingTop: 40,
      paddingBottom: 40,
      backgroundColor: jssVariables.baseColor,
      borderTop: '1px solid #ececec',
    },
    logo: {
      fontSize: 32,
      fontWeight: 700,
      color: '#000',
      lineHeight: 1,
    },
    copyright: {
      color: jssVariables.darkGreyColor,
      fontSize: 14,
      fontWeight: 500,
      marginTop: 10,
      marginBottom: 10,
    },
    social: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginTop: 15,
      '& svg': {
        fontSize: 18,
        marginRight: 10,
      }
    },
    subtitle: {
      fontSize: 18,
      fontWeight: 600,
      marginBottom: 10,
    },
    text: {
      fontSize: 14,
      fontWeight: 500,
    }
  });

export default FooterStyle;

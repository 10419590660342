import { createStyles } from '@material-ui/core/styles';
import jssVariables from "../../../helpers/variables";

const AboutStyle = (theme: any) =>
  createStyles({
    content: {
      position: 'relative',
      minHeight: '100vh',
      flexGrow: 1,
      width: '100%',
      backgroundColor: jssVariables.baseColor,
    },
    title: {
      fontSize: 32,
    }
  });

export default AboutStyle;

import React from "react";
import { connect } from 'react-redux';
import {withStyles} from "@material-ui/core";
import posed from "react-pose";
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import {RootState} from "../../../../engine/init/rootReducer";
import uiActions from "../../../../engine/core/ui/action";
import SignUpStyle from "./SignUpStyle"
import SignUpForm from "./SigUpForm";

interface SignUpProps {
  classes: any;
  signUpModalStatus: boolean;
  setSignUpModalStatus: (preloader: boolean) => object;
  setSignInModalStatus: (preloader: boolean) => object;
}
type AppBarDispatchToProps = {
  setSignUpModalStatus: (preloader: boolean) => object;
  setSignInModalStatus: (preloader: boolean) => object;
};

const mapStateToProps = (state: RootState) => ({
  signUpModalStatus: state.ui.get('signUpModalStatus'),
});

const mapDispatchToProps: AppBarDispatchToProps = {
  setSignUpModalStatus: uiActions.setSignUpModalStatus,
  setSignInModalStatus: uiActions.setSignInModalStatus,
};

const Div = posed.div({
  show: {
    opacity:      1,
    transition:   { duration: 200 },
    applyAtStart: { display: 'flex' },
  },
  hide: {
    opacity:    0,
    transition: { duration: 200 },
    applyAtEnd: { display: 'none' },
  },
});
const Modal = posed.div({
  show: {
    opacity:      1,
    scale:        1,
    transition:   { duration: 200 },
    applyAtStart: { display: 'block' },
  },
  hide: {
    opacity:    0,
    scale:      0.7,
    transition: { duration: 200 },
    applyAtEnd: { display: 'none' },
  },
});
class SignUp extends React.PureComponent<SignUpProps>{
  closeSignUpModal = () => {
    const { setSignUpModalStatus, signUpModalStatus } = this.props;
    setSignUpModalStatus(!signUpModalStatus);
  };

  render() {
    const {classes, signUpModalStatus} = this.props;
    return (
      <Div className={classes.overlay} initialPose="hide" pose={ signUpModalStatus ? 'show' : 'hide'}>
        <Modal className={classes.modal}>
          <button type="button" aria-label="close modal" onClick={this.closeSignUpModal} className={classes.close} />
          <div className={classes.modalWrapper}>
            {/* <a href="/" className={classes.logo}> */}
            {/*  Danavis */}
            {/* </a> */}
            <h1 className={classes.title}>Начнём?</h1>
            {/* @ts-ignore */}
            <SignUpForm classes={classes} />
          </div>
        </Modal>
        <div className={classes.overlayClose} role="button" aria-label="close modal" tabIndex={0} onClick={this.closeSignUpModal} onKeyDown={this.closeSignUpModal} />
      </Div>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(SignUpStyle, {
    name: 'SignUp',
  })(SignUp)
);

import { createStyles } from '@material-ui/core';
import jssVariables from "../../../../../helpers/variables";


const BadgeInfoStyle = (theme: any) => 
  createStyles({
    badge: {
      backgroundColor: '#F4F6F8',
      borderRadius: 10,
      margin: '10px auto',
      maxWidth: 250,
      display: 'flex',
      alignItems: 'center',
      padding: 15,
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      '&:hover': {
        backgroundColor: '#cfd0d0',
      },
    },
    avatar: {
      marginRight: 15,
      backgroundColor: '#1890FF',
      transition: 'all 0.3s ease',
    },
    name: {
      fontSize: 16,
    }
  });

export default BadgeInfoStyle;
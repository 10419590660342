import asyncTypes from './asyncTypes';

const uiActionsAsync = Object.freeze({
  checkAssistantAsync: (status: any) => ({
    type: asyncTypes.CHECK_ASSISTANT_ASYNC,
    payload: status
  }),
});

export default uiActionsAsync;

import { createStyles } from '@material-ui/core';
import jssVariables from '../../../../../helpers/variables';

const LoginStyle = (theme: any) =>
  createStyles({ 
    authFormWrapper: {
      width: '100%',
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundImage: jssVariables.baseColor,
      padding: '0 15px',
    },
    left: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100vh',
      [theme.breakpoints.up('md')]: {
        width: '50%',
      }
    },
    right: {
      display: 'none',
      width: '50%',
      height: '100vh',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      }
    },
    form: {
      position: 'relative',
      maxWidth: 360,
      width: '100%',
      padding: '30px 20px',
      backgroundColor: jssVariables.baseColor,
      borderRadius: 3,
      zIndex: 1,
    },
    formTitle: {
      fontSize: 22,
      fontWeight: 700,
      marginBottom: 40,
      lineHeight: 1,
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        fontSize: 28,
      },
      '& span': {
        display: 'inline-block',
        lineHeight: 1,
      },
      '& span:last-of-type': {
        color: jssVariables.firstBrandColor
      },
    },
    controlsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 10,
    },
    checkboxLabel: {
      '& span': {
        fontSize: 14,
        fontFamily: 'inherit',
        lineHeight: '1em',
      }
    },
    forgotPassLink: {
      fontSize: 14,
    },
    submitButton: {
      display: 'block',
      width: '100%',
      marginTop: 10,
    },
    wrapper: {
      position: 'relative',
    },
    formError: {
      position: 'absolute',
      top: '100%',
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: '#ffe3e0',
      borderRadius: 4,
      borderBottom: '2px solid #ff3e2c',
      padding: 15,
      width: '100%',
      maxWidth: 320,
      fontWeight: 600,
      fontSize: 14,
      zIndex: 2,
    },
    formErrorX: {
      position: 'relative',
      borderRadius: '50%',
      backgroundColor: '#ff3e2c',
      marginRight: 10,
      width: 30,
      height: 30,
      '&::before': {
        content: "''",
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        borderRadius: 15,
        width: 15,
        height: 2,
        backgroundColor: '#fff',
        transform: 'rotate(-45deg)',
      },
      '&::after': {
        content: "''",
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        borderRadius: 15,
        width: 15,
        height: 2,
        backgroundColor: '#fff',
        transform: 'rotate(45deg)',
      }
    }
  });

export default LoginStyle;
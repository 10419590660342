import { createStyles } from '@material-ui/core/styles';

const ApplicationStyle = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    }
  });

export default ApplicationStyle;

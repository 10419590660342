const asyncTypes = Object.freeze({
  SIGN_UP_ASYNC: 'SIGN_UP_ASYNC',
  SIGN_IN_ASYNC: 'SIGN_IN_ASYNC',
  SIGN_OUT_ASYNC: 'SIGN_OUT_ASYNC',
  UPDATE_TOKEN_ASYNC: 'UPDATE_TOKEN_ASYNC',
  GET_USERS_ASYNC: 'GET_USERS_ASYNC',
  UPDATE_USER_ASYNC: 'UPDATE_USER_ASYNC'
});

export default asyncTypes;

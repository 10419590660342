import { createStyles } from '@material-ui/core/styles';
import {  } from '@fortawesome/fontawesome-svg-core';
import jssVariables from "../../../../helpers/variables";

const ProductStyle = (theme: any) =>
  createStyles({
    card: {
      position: 'relative',
      width: '100%',
      zIndex: 0,
    },
    cardWrapper: {
      position: 'relative',
      width: '100%',
      height: 370,
      overflow: 'hidden',
      borderRadius: 7,
      zIndex: 3,
      transition: 'transform .2s ease, box-shadow .2s',
      '&:hover .swiper-button-prev': {
        visibility: 'visible',
        opacity: 1,
      },
      '&:hover .swiper-button-next': {
        visibility: 'visible',
        opacity: 1,
      },
      '&:hover .swiper-button-disabled': {
        visibility: 'visible',
        opacity: '0.35 !important',
      },
      '& .swiper-container': {
        height: '100%',
        '&::before': {
          content: "''",
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: 100,
          backgroundImage: 'linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0))',
          zIndex: 3,
        },
      },
      '& .swiper-pagination': {
        position: 'absolute',
        bottom: 10,
        zIndex: 4,
      },
      '& .swiper-pagination-bullet': {
        height: 5,
        width: 5,
        borderRadius: '50%',
        background: 'rgba(255, 255, 255, 0.7)',
        transition: 'all 0.3s ease',
      },
      '& .swiper-pagination-bullet.swiper-pagination-bullet-active': {
        transform: 'scale(1.15)',
      },
      '& .swiper-button-prev': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 32,
        height: 32,
        border: '1px solid rgba(0, 0, 0, 0.08)',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        color: jssVariables.fontColor,
        borderRadius: '50%',
        visibility: 'hidden',
        opacity: 0,
        boxShadow: 'transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px, rgba(0, 0, 0, 0.18) 0px 2px 4px !important',
        transition: 'visibility .3s, opacity .3s, transform .3s',
        '&::after': {
          fontSize: 10,
          fontWeight: 700,
          color: jssVariables.fontColor,
        },
        '&:hover': {
          transform: 'scale(1.1)',
        }
      },
      '& .swiper-button-next': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 32,
        height: 32,
        border: '1px solid rgba(0, 0, 0, 0.08)',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        color: jssVariables.fontColor,
        borderRadius: '50%',
        visibility: 'hidden',
        opacity: 0,
        boxShadow: 'transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px, rgba(0, 0, 0, 0.18) 0px 2px 4px !important',
        transition: 'visibility .3s, opacity .3s, transform .3s',
        '&::after': {
          fontSize: 10,
          fontWeight: 700,
          color: jssVariables.fontColor,
        },
        '&:hover': {
          transform: 'scale(1.1)',
        }
      },
      '& .swiper-button-disabled': {
        visibility: 'hidden',
        transition: 'visibility .3s, opacity .3s',
      },
    },
    cardImg: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    cardHeart: {
      position: 'absolute',
      top: 15,
      right: 15,
      width: 24,
      height: 24,
      transition: 'transform .3s',
      zIndex: 1,
      '&:hover': {
        transform: 'scale(1.05)',
      }
    },
    cardTitle: {
      fontSize: 18,
      fontWeight: 700,
      color: '#000',
      marginTop: 5,
    },
    cardRate: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginTop: 10,
      '& span': {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: 1,
        marginLeft: 5,
        color: '#000',
      },
    },
    cardStar: {
      color: 'rgb(255, 56, 92)',
    },
  });

export default ProductStyle;

import React from "react";
import { connect } from 'react-redux';
import {withStyles} from "@material-ui/core";
import posed from "react-pose";
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import {RootState} from "../../../../engine/init/rootReducer";
import uiActions from "../../../../engine/core/ui/action";
import SignInStyle from "./SignInStyle";
import SignInForm from "./SigInForm";
import CustomButton from "../../ui/CustomButton/CustomButton";

interface SignInProps {
  classes: any;
  signInModalStatus: boolean;
  signUpModalStatus: boolean;
  setSignUpModalStatus: (preloader: boolean) => object;
  setSignInModalStatus: (preloader: boolean) => object;
}

type AppBarDispatchToProps = {
  setSignUpModalStatus: (preloader: boolean) => object;
  setSignInModalStatus: (preloader: boolean) => object;
};

const mapStateToProps = (state: RootState) => ({
  signInModalStatus: state.ui.get('signInModalStatus'),
  signUpModalStatus: state.ui.get('signUpModalStatus'),

});

const mapDispatchToProps: AppBarDispatchToProps = {
  setSignUpModalStatus: uiActions.setSignUpModalStatus,
  setSignInModalStatus: uiActions.setSignInModalStatus,
};

const Div = posed.div({
  show: {
    opacity:      1,
    transition:   { duration: 200 },
    applyAtStart: { display: 'flex' },
  },
  hide: {
    opacity:    0,
    transition: { duration: 200 },
    applyAtEnd: { display: 'none' },
  },
});

const Modal = posed.div({
  show: {
    opacity:      1,
    scale:        1,
    transition:   { duration: 200 },
    applyAtStart: { display: 'block' },
  },
  hide: {
    opacity:    0,
    scale:      0.7,
    transition: { duration: 200 },
    applyAtEnd: { display: 'none' },
  },
});

class SignIn extends React.PureComponent<SignInProps>{

  toggleSignInModal = () => {
    const { setSignInModalStatus, signInModalStatus } = this.props;
    setSignInModalStatus(!signInModalStatus);
  };

  toggleSignUpModal = () => {
    const { setSignUpModalStatus, signUpModalStatus } = this.props;
    setSignUpModalStatus(!signUpModalStatus);
    this.toggleSignInModal();
  };

  render() {
    const {classes, signInModalStatus} = this.props;
    return (
      <Div className={classes.overlay} initialPose="hide" pose={ signInModalStatus ? 'show' : 'hide'}>
        <Modal className={classes.modal}>
          <button type="button" aria-label="close modal" onClick={this.toggleSignInModal} className={classes.close} />
          <div className={classes.modalWrapper}>
            <a href="/" className={classes.logo}>
              Danavis
            </a>
            {/* <h1 className={classes.title}>Приступим?</h1> */}
            <p className={classes.text}>
              Войдите или <button className={classes.toggleModal} onClick={this.toggleSignUpModal}>
                зарегистрируйтесь
              </button>
            </p>
            {/* @ts-ignore */}
            <SignInForm classes={classes} />
          </div>
        </Modal>
        <div className={classes.overlayClose} role="button" aria-label="close modal" tabIndex={0} onClick={this.toggleSignInModal} onKeyDown={this.toggleSignInModal} />
      </Div>
    );
  }
}
// export default function Checkboxes() {
//   const [checked, setChecked] = React.useState(true);
//
//   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setChecked(event.target.checked);
//   };
// }
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(SignInStyle, {
    name: 'SignIn',
  })(SignIn)
);
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import ProductStyle from './ProductStyle';
import HeartSVG from './img/heart.svg';
import 'swiper/components/navigation/navigation.scss';

interface ProductProps {
  classes: any;
  card: any;
  addToFavorite: (id: number) => void;
}

SwiperCore.use([Pagination, Navigation]);

const Product: React.FC<ProductProps> = ({ classes, card, addToFavorite }) => {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Link to="/" className={classes.card}>
        <div className={classes.cardWrapper}>
          <Swiper
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
          >
            {card.img && card.img.map((item: any) => (<SwiperSlide key={item}>
              <img src={item} alt="" className={classes.cardImg} />
            </SwiperSlide>))}
          </Swiper>
          <img
            src={HeartSVG}
            alt="add-to-favorite"
            role="presentation"
            className={classes.cardHeart}
            onClick={() => addToFavorite(card.id)}
            onKeyPress={() => addToFavorite(card.id)}
          />
        </div>
        <div className={classes.cardRate}>
          <FontAwesomeIcon icon={faStar} className={classes.cardStar}  />
          <span>5.0 (23)</span>
        </div>
        <p className={classes.cardTitle}>{card.title}</p>
      </Link>
    </Grid>
  )
};

export default withStyles(ProductStyle, {
  name: 'Product',
})(Product);
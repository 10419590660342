import {put, apply, delay, StrictEffect} from 'redux-saga/effects';
import { push } from 'connected-react-router/immutable';
import api from '../../../config/api';
import userActions from '../../action';

export default function* callSignUpWorker({ payload: userData }: any): Generator<StrictEffect> {
  const response: any = yield apply(api, api.user.signUp, [userData]);
  if (response.status === 200 && response.data.status === 'ok') {
    yield put(push('/'));
  } else if (response.status === 200) {
    if (response.data.email) yield put(userActions.setFormError(response.data.email));
  }  else if (response.status >= 300) {
    yield put(userActions.setFormError(response.statusText));
  }
  yield put(userActions.setSignUpPreloader(false));
  yield delay(3000);
  yield put(userActions.setFormError(''));
}

import types from './types';

const uiActions = Object.freeze({
  setSignUpModalStatus: (status: boolean) => ({
    type: types.SIGN_UP_MODAL_STATUS,
    payload: status
  }),
  setSignInModalStatus: (status: boolean) => ({
    type: types.SIGN_IN_MODAL_STATUS,
    payload: status
  }),
  setLiveChatModalStatus: (status: boolean) => ({
    type: types.LIVE_CHAT_MODAL_STATUS,
    payload: status
  }),
  setBotMessagesPreloaderStatus: (status: boolean) => ({
    type: types.BOT_MESSAGES_PRELOADER_STATUS,
    payload: status
  }),
  setGlobalPreloaderStatus: (status: boolean) => ({
    type: types.GLOBAL_PRELOADER_STATUS,
    payload: status
  })
});

export default uiActions;

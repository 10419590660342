import React from 'react';
import {connect} from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import GlobalPreloaderStyle from './GlobalPreloaderStyle';
import {RootState} from "../../../../../engine/init/rootReducer";

interface GlobalPreloaderProps {
  classes: any;
  globalPreloaderStatus?: boolean;
}

const mapStateToProps = (state: RootState) => ({
  globalPreloaderStatus: state.ui.get('globalPreloaderStatus'),
});

class GlobalPreloader extends React.PureComponent<GlobalPreloaderProps> {
  render() {
    const { classes, globalPreloaderStatus } = this.props;

    return (
      <div className={`${classes.root} ${globalPreloaderStatus && classes.active}`}>
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={24}
          thickness={4}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className={classes.top}
          classes={{
            circle: classes.circle,
          }}
          size={24}
          thickness={4}
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  null
)(withStyles(GlobalPreloaderStyle, {
  name: 'GlobalPreloader'
})(GlobalPreloader));

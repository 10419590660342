import React from 'react';
import { Form, Field, reduxForm } from 'redux-form/immutable';
import { Link } from 'react-router-dom';
import { faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import { FormControlLabel } from "@material-ui/core";
import CustomInput from '../../../ui/CustomInput/CustomInput';
import CustomButtonSquare from "../../ui/CustomButtonSquare/CustomButtonSquare";

const validate = (values: any) => {
  const formValues = values.toJS();
  const errors: any = {};
  const requiredFields: Array<string> = ['email', 'password'];
  requiredFields.forEach((field: string) => {
    if (!formValues[field]) {
      errors[field] = 'Заполните поле';
    }
  });
  return errors;
};

interface LoginFormProps {
  classes?: any;
  handleSubmit: (data: any) => void;
  signInPreloader?: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({ handleSubmit, signInPreloader, classes }) => {
  return (
    <Form onSubmit={handleSubmit} className={classes.form}>
      <h2 className={classes.formTitle}>
        <span>Здравствуйте</span>
        <span>Войдите в админ-панель</span>
      </h2>
      <Field
        type="email"
        name="email"
        component={CustomInput}
        placeholder='Эл. адрес'
        id="email"
        icon={faUser}
      />
      <Field
        type="password"
        name="password"
        component={CustomInput}
        placeholder='Пароль'
        id="password"
        icon={faLock}
      />
      <div className={classes.controlsWrapper}>
        <FormControlLabel
          className={classes.checkboxLabel}
          control={
            <Checkbox
              name="checkedB"
              color="default"
            />
          }
          label="Запомнить меня"
        />
        <Link to='/' className={classes.forgotPassLink} >
          Забыли пароль
        </Link>
      </div>
      <CustomButtonSquare
        type='submit'
        customClass={classes.submitButton}
        preloader={signInPreloader}>
        Войти
      </CustomButtonSquare>
    </Form>
  );
};

export default reduxForm({
  form: 'LoginForm',
  validate
})(LoginForm);

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AboutStyle from './AboutStyle';
import Products from "../../components/block/Products/Products";


interface AboutProps {
  readonly classes: any;
}

const About: React.FC<AboutProps> = ({ classes }) => {
  return (
    <main className={classes.content}>
      <h1 className={classes.title}>О компании</h1>
    </main>
  );
};

export default  withStyles(AboutStyle, {
  name: 'About'
})(About);

import { all, call, fork } from 'redux-saga/effects';
import watchersUser from '../core/user/saga/watchers';
// import listenServerSaga from '../core/config/socket';

export default function* rootSaga(dispatch: any, getState: any) {
  yield all([
    (call as any)(watchersUser, getState),
    // (fork as any)(listenServerSaga),
  ]);
}

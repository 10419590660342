import React from 'react';
import { Form, Field, reduxForm } from 'redux-form/immutable';
import { Link } from 'react-router-dom';
import { faLock, faIdCard, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import CustomInput from '../../ui/CustomInput/CustomInput';
import CustomButton from '../../ui/CustomButton/CustomButton';

const validate = (values: any) => {
  const formValues = values.toJS();
  const errors: any = {};
  const requiredFields: Array<string> = ['username', 'email', 'password'];
  requiredFields.forEach((field: string) => {
    if (!formValues[field]) {
      errors[field] = 'Заполните поле';
    }
  });
  return errors;
};

interface SignUpFormProps {
  classes?: any;
  handleSubmit: (data: any) => void;
  preloader?: boolean;
}

const SignUpForm: React.FC<SignUpFormProps> = ({ handleSubmit, preloader, classes }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Field
        type="text"
        name="username"
        component={CustomInput}
        placeholder="Имя пользователя"
        id="username"
        icon={faIdCard}
      />
      <Field
        type="email"
        name="email"
        component={CustomInput}
        placeholder="Эл. Адрес"
        id="email"
        icon={faEnvelopeOpenText}
      />
      <Field
        type="password"
        name="password"
        component={CustomInput}
        placeholder="Пароль"
        id="password"
        icon={faLock}
      />
      {/* <div className={classes.checkbox}> */}
      <p className={classes.text2}>
        Нажимая на кнопку <strong>Зарегистрироваться</strong>, вы соглашаетесь с нашими <Link to="/">условиями обслуживания и политикой конфиденциальности</Link>
      </p>
      {/* </div> */}
      <CustomButton type="submit" customClass={classes.buttonModal} preloader={preloader} disabled={preloader}>
        Зарегистрироваться
      </CustomButton>
    </Form>
  );
};

export default reduxForm({
  form: 'SignUpForm',
  validate
})(SignUpForm);

import { createStyles } from '@material-ui/core';


const SidebarStyle = (theme: any) =>
  createStyles({
    sidebar: {
      width: 280,
      height: '100vh',
      position: 'fixed',
      zIndex: 1101,
      borderRight: '1px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      // Стили скролла для Firefox
      'scrollbar-color':'#999 #fff',
      scrollbarWidth:  'thin',

      // Стили скролла для WebKit
      '&::-webkit-scrollbar': {
        width: 7,
        height: 3,
        backgroundColor: '#fff',
      },
      
      '&::-webkit-scrollbar:hover': {
        backgroundColor: '#fff',
      },
    
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#999',
        borderRadius: 5,
        boxShadow: 'inset 1px 1px 10px #ccc',
      },
    
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#555',
      },
    },
    logo: {
      margin: '20px auto',
      textAlign: 'center',
    },
    orange: {
      background: 'orange',
    },
    accordionWrapper: {
      flexDirection: 'column',
      padding: '8px 0',
    },
    divider: {
      marginTop: 10,
      marginBottom: 10,
    },
    sectionTitle: {
      fontSize: 15,
      fontWeight: 600,
      textTransform: 'uppercase',
      marginTop: 15,
      marginBottom: 15,
      padding: '0 16px',
    },
    accordion: {
      boxShadow: 'none',
      borderBottom: '1px solid #ececec',
      borderRadius: 0,
    }
  });

export default SidebarStyle;

import { createStyles } from '@material-ui/core';
import jssVariables from '../../../../helpers/variables';

const CustomInputStyle = () =>
  createStyles({
    label: {
      position: 'relative',
      display: 'flex',
      border: `1px solid #ebeff5`,
      borderRadius: 6,
      paddingLeft: 45,
      paddingRight: 10,
      height: 50,
      marginTop: 20,
      transition: 'border-color .3s',
      '& input': {
        width: '100%',
        height: '100%',
        border: 0,
        fontFamily: jssVariables.textColor,
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: 0.2,
        color: jssVariables.darkGreyColor,
        backgroundColor: 'transparent',
        '&:focus': {
          outline: 'none'
        }
      },
      '&:hover': {
        borderColor: '#dae1ed',
      }
    },
    labelActive: {
      borderColor: jssVariables.darkGreyColor,
      '& $icon': {
        color: jssVariables.darkGreyColor,
      }
    },
    labelError: {
      borderColor: jssVariables.errorColor,
      '& $icon': {
        color: jssVariables.errorColor,
      }
    },
    icon: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 15,
      marginTop: 'auto',
      marginBottom: 'auto',
      color: '#758191',
      transition: 'color .3s'
    },
    error: {
      position: 'absolute',
      bottom: -18,
      left: 0,
      color: jssVariables.errorColor,
      fontSize: 11,
      fontWeight: 500,
      letterSpacing: 0.2
    }
  });

export default CustomInputStyle;

import { put, apply, delay, StrictEffect } from 'redux-saga/effects';
import { push } from 'connected-react-router/immutable';
import api from '../../../config/api';
import userActions from '../../action';

export default function* callSignInWorker({ payload: tokenBase64 }: any): Generator<StrictEffect> {
  const response: any = yield apply(api, api.user.signIn, [tokenBase64]);
  if (response.status === 200 && response.data.token !== null) {
    localStorage.setItem('userInfo', JSON.stringify(response.data));
    yield put(userActions.setUserInfo(response.data));
    yield put(push('/admin/dashboard'));
  } else if (response.status >= 300) {
    yield put(userActions.setFormError(response.data));
  }
  yield put(userActions.setSignInPreloader(false));
  yield delay(3000);
  yield put(userActions.setFormError(''));
}

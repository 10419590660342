import React from 'react';
import { withStyles } from '@material-ui/styles';
import {connect} from "react-redux";
import posed from "react-pose";
import LiveChatStyle from './LiveChatStyle';
import {RootState} from "../../../../engine/init/rootReducer";
import uiActions from "../../../../engine/core/ui/action";
import LiveChatScroll from "./LiveChatScroll";
import uiActionsAsync from "../../../../engine/core/ui/saga/asyncActions";

interface LiveChatProps {
  classes: any;
  liveChatModalStatus: boolean;
  botMessagePreloaderStatus: boolean;
  setLiveChatModalStatus: (status: boolean) => object;
  setBotMessagesPreloaderStatus: (status: boolean) => object;
  checkAssistantAsync: (status: boolean) => object;
}

interface LiveChatState {
  chatFieldPlaceholder: string;
  listMessages: Array<string>;
}

type LiveChatDispatchToProps = {
  setLiveChatModalStatus: (status: boolean) => object;
  setBotMessagesPreloaderStatus: (status: boolean) => object;
  checkAssistantAsync: (status: boolean) => object;
};

const mapStateToProps = (state: RootState) => ({
  liveChatModalStatus: state.ui.get('liveChatModalStatus'),
  botMessagePreloaderStatus: state.ui.get('botMessagePreloaderStatus'),
});

const mapDispatchToProps: LiveChatDispatchToProps = {
  setLiveChatModalStatus: uiActions.setLiveChatModalStatus,
  setBotMessagesPreloaderStatus: uiActions.setBotMessagesPreloaderStatus,
  checkAssistantAsync: uiActionsAsync.checkAssistantAsync,
};

const Div = posed.div({
  show: {
    opacity:      1,
    transition:   { duration: 200 },
    applyAtStart: { display: 'flex' },
  },
  hide: {
    opacity:    0,
    transition: { duration: 200 },
    applyAtEnd: { display: 'none' },
  },
});

const Modal = posed.div({
  show: {
    opacity:      1,
    scale:        1,
    transition:   { duration: 200 },
    applyAtStart: { display: 'block' },
  },
  hide: {
    opacity:    0,
    scale:      0.7,
    transition: { duration: 200 },
    applyAtEnd: { display: 'none' },
  },
});

class LiveChat extends React.Component<LiveChatProps, LiveChatState>  {
  state = {
    chatFieldPlaceholder: '',
    listMessages: [],
  };

  componentDidUpdate(prevProps: Readonly<LiveChatProps>, prevState: Readonly<LiveChatState>, snapshot?: any): void {
    if (this.props.liveChatModalStatus !== prevProps.liveChatModalStatus && this.props.liveChatModalStatus) {
      this.props.checkAssistantAsync(true);
      setTimeout(() => {
        this.setState((state: any) => state.listMessages.push('Здравствуйте! У вас есть вопрос или вам нужна помощь?'));
        setTimeout(() => {
          this.setState((state: any) => state.listMessages.push('Напишите, что вас интересует, и я вам обязательно помогу.'));
          this.props.setBotMessagesPreloaderStatus(!this.props.botMessagePreloaderStatus);
        }, 2000);
      }, 1000);
    }
  }

  chatFieldOnFocus = (e: any) => {
    this.setState({
      chatFieldPlaceholder: e.target.getAttribute('data-placeholder'),
    });
    e.target.setAttribute('data-placeholder', '');
  };

  chatFieldOnBlur = (e: any) => {
    e.target.setAttribute('data-placeholder', this.state.chatFieldPlaceholder);
  };

  closeLiveChatModal = () => {
    const { setLiveChatModalStatus, liveChatModalStatus, setBotMessagesPreloaderStatus, botMessagePreloaderStatus } = this.props;
    setLiveChatModalStatus(!liveChatModalStatus);
    setBotMessagesPreloaderStatus(!botMessagePreloaderStatus);
    this.setState({ listMessages: [] });
  };

  render() {
    const { classes, liveChatModalStatus, botMessagePreloaderStatus } = this.props;
    const { listMessages } = this.state;

    return (
      <Div className={classes.overlay} pose={ liveChatModalStatus ? 'show' : 'hide' }>
        <Modal className={classes.modal}>
          <button type="button" aria-label="close modal" onClick={this.closeLiveChatModal} className={classes.close} />
          <div className={classes.modalWrapper}>
            <div className={classes.modalLeft} />
            <div className={classes.modalRight}>
              <p className={classes.title}>Напишите нам</p>
              <div className={classes.chat}>
                <LiveChatScroll classes={classes} listMessages={listMessages} botMessagePreloaderStatus={botMessagePreloaderStatus} onScrolled={(e: any) => console.log('the list was scrolled')} />
                <div className={classes.chatField} contentEditable data-placeholder="Ваше сообщение" onFocus={this.chatFieldOnFocus} onBlur={this.chatFieldOnBlur} />
              </div>
            </div>
          </div>
        </Modal>
        <div className={classes.overlayClose} role="button" aria-label="close modal" tabIndex={0} onClick={this.closeLiveChatModal} onKeyDown={this.closeLiveChatModal} />
      </Div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(LiveChatStyle, {
    name: 'LiveChat'
  })(LiveChat)
);

import { createStyles } from '@material-ui/core/styles';

const UsersStyle = (theme: any) =>
  createStyles({
    adminDashboard: {
      display: 'flex',
      width: '100%',
      minHeight: '100vh',
      overflow: 'hidden',
    },
    container: {
      padding: 20,
    },
    content: {
      paddingLeft: 280,
      paddingTop: 74,
      width: '100%',
    },
    header: {
      backgroundColor: '#ececec',
      padding: '10px 25px',
    },
    title: {
      fontSize: 42,
      fontWeight: 500
    },
    fixedPaper: {
      minHeight: 150,
      padding: '0 20px',
      borderRadius: 4,
      display: 'flex',
      alignItems: 'center',
      boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
      justifyContent: 'space-around',
    },
    slider: {
      backgroundColor: 'rgba(22, 28, 36, 0.72)',
    },
    paperText: {
      width: '50%',
    },
    table: {
      borderLeft: 'none',
      borderRight: 'none',
      borderTop: 'none',
      borderRadius: 0,
      overflowX: 'auto',
      '& th .p-column-title': {
        display: 'block',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      '& td': {
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }
    }
  });

export default UsersStyle;

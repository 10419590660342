import { createStyles } from '@material-ui/core/styles';
// import jssVariables from '../../../../helpers/variables';

const CategoriesStyle = (theme: any) =>
  createStyles({
    container: {
      marginTop: 40,
    },
    categories: {
      marginTop: 25,
      marginBottom: 100,
    },
  });

export default CategoriesStyle;

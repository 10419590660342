import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Accordion, Button, Divider, List, ListItem, ListItemText, ListItemIcon, ListSubheader, AccordionSummary, Typography, AccordionDetails } from '@material-ui/core';
import { Email as EmailIcon } from '@material-ui/icons';
import NotificationStyle from './NotificationStyle';

interface NotificationProps {
  classes: any;
  icon?: any;
  title: string;
  description: string;
}

const Notification: React.FC<NotificationProps> = ({
  classes,
  icon = <EmailIcon />,
  title,
  description,
}) => {

  return (
    
    <ListItem button className={classes.notificationWrapper}>
      <div className={classes.notification}>
        {icon}
        <div className={classes.notificationText}>
          <h5>{title}</h5>
          <p>{description}</p>
        </div>
      </div>
    </ListItem>

  )

}

export default withStyles(NotificationStyle, {
  name: 'Notification'
})(Notification);

import {put, apply, StrictEffect} from 'redux-saga/effects';
import api from '../../../config/api';
import userActions from '../../action';
import userActionsAsync from "../asyncActions";
import uiActions from "../../../ui/action";

export default function* callGetUsersWorker({ payload: token }: any): Generator<StrictEffect> {
  const response: any = yield apply(api, api.user.getListOfUsers, [token]);
  if (response.status === 200 && response.statusText === 'OK') {
    yield put(userActions.setListOfUsers(response.data));
    yield put(uiActions.setGlobalPreloaderStatus(false));
  } else if (response.status >= 300) {
    if (response.data === 'jwt expired') {
      yield put(userActionsAsync.updateTokenAsync(JSON.parse(localStorage.getItem('userInfo') as string).token));
    }
  }
}

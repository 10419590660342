import { createStyles } from "@material-ui/core";
import jssVariables from '../../../../helpers/variables';

const SignInStyle = () =>
  createStyles( {
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100vh',
      backgroundColor: jssVariables.lowGreyColor
    },
    modal: {
      backgroundColor: 'white',
      position: 'absolute',
      zIndex: 1101,
      width: 485,
      height: 'auto',
      borderRadius: 15,
      overflow: 'hidden',
      paddingLeft: 80,
      paddingRight: 80,
    },
    overlay: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1300,
      backgroundColor: 'rgba(0,0,0,0.6)',
    },
    overlayClose: {
      backgroundColor: 'rgba(0,0,0,.4)',
      width: '100%',
      height: '100%',
    },
    modalRight: {
      width: 480,
      height: '100%',
      backgroundColor: jssVariables.secondColor,
      padding: 24,
    },
    checkbox: {
      height: 24,
      border: 'none',
      padding: 0,
      cursor: 'pointer',
      marginTop: 20,
      marginBottom: 25,
    },
    buttonModal: {
      display: 'block',
      width: '100%',
      padding: "0px 25px",
      backgroundColor: jssVariables.firstBrandColor,
      backgroundImage: 'none',
      marginRight: 'auto',
      marginLeft: 'auto',
      color: '#fff',
      marginBottom: 35,
      textTransform: 'none',
      fontSize: 17,
      '&:hover': {
        backgroundColor: jssVariables.hoveredFirstBrandColor,
      }
    },
    close: {
      position: 'absolute',
      top: 24,
      right: 24,
      width: 24,
      height: 24,
      border: 'none',
      padding: 0,
      backgroundColor: 'transparent',
      cursor: 'pointer',
      '&::before': {
        content: "''",
        position: 'absolute',
        top: 0,
        left: 11,
        width: 2,
        height: 24,
        backgroundColor: '#000',
        transform: 'rotate(-45deg)',
      },
      '&::after': {
        content: "''",
        position: 'absolute',
        top: 0,
        left: 11,
        width: 2,
        height: 24,
        backgroundColor: '#000',
        transform: 'rotate(45deg)',
      }
    },
    text: {
      fontSize: 15,
      textAlign: 'center',
      fontWeight: 600,
      lineHeight: 1,
      paddingTop: 16,
      marginBottom: 20,
    },
    text2: {
      fontSize: 13,
      textAlign: 'center',
      fontWeight: 600,
      marginBottom: -31,
    },
    text3: {
      fontSize: 12,
      textAlign: 'end',
      fontWeight: 600,
      marginBottom: 10,
      paddingRight: 5,
    },

    modalWrapper: {
      display: 'fixed',
      justifyContent: 'space-between',
      alignItems: 'center',
      overflow: 'hidden',
    },
    title: {
      color: '#000',
      fontSize: 35,
      marginTop: 13,
      fontWeight: 700,
      textAlign: 'center',
    },
    logo: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 36,
      fontWeight: 700,
      paddingTop: 25,
      color: jssVariables.textColor,
      position: 'relative',
      zIndex: 1000,
    },
    toggleModal: {
      backgroundColor: 'transparent',
      border: 'none',
      padding: 0,
      fontWeight: 600,
      color: jssVariables.firstBrandColor,
      '&:focus': {
        outline: 'none',
      }
    },
  });

export default SignInStyle;
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShower , faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import Grid from "@material-ui/core/Grid";
import CategoryStyle from './CategoryStyle';
import CustomButton from "../CustomButton/CustomButton";

interface CategoryProps {
  classes: any;
  card: any;
  hoverEffect: (id: any) => void;
  hoverEffectOut: () => void;
  hoverItem: any;
}

const Category: React.FC<CategoryProps> = ({ classes, card, hoverItem, hoverEffect, hoverEffectOut }) => {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      {/* eslint-disable-next-line no-nested-ternary */}
      <Link to="/" className={`${classes.card} ${hoverItem === card.id ? classes.cardOpacity : hoverItem === null ? classes.cardOpacity : ''}`} onMouseOver={() => hoverEffect(card.id)} onMouseOut={() => hoverEffectOut()}>
        <div className={classes.cardImg}>
          <img src={card.img} alt="" />
        </div>
        <div className={classes.cardBottom}>
          <p className={classes.cardBottomTitle}>{card.title}</p>
          <div className={classes.cardBottomRow}>
            <p className={classes.cardBottomShowers}>
              <FontAwesomeIcon icon={faShower} />
              <span>87</span>
            </p>
            <p className={classes.cardBottomShowers}>
              <FontAwesomeIcon icon={faShoppingCart} />
              <span>1000+</span>
            </p>
          </div>
        </div>
        <div className={classes.cardHidden}>
          <p className={`${classes.cardBottomTitle} ${classes.cardHiddenTitle}`}>{card.title}</p>
          <p className={classes.cardHiddenPrice}>от 2355 грн</p>
          <div className={classes.cardHiddenShowers}>
            <div>
              <span>87</span>
              <p>Видов</p>
            </div>
            <div>
              <span>2456</span>
              <p>Заказов</p>
            </div>
          </div>
          <CustomButton customClass={classes.cardHiddenButton}>
            Смотреть все
          </CustomButton>
        </div>
      </Link>
    </Grid>
  )
};

export default withStyles(CategoryStyle, {
  name: 'Category',
})(Category);
const types = Object.freeze({
  SET_USER_INFO: 'SET_USER_INFO',
  SET_SIGN_UP_PRELOADER: 'SET_SIGN_UP_PRELOADER',
  SET_SIGN_IN_PRELOADER: 'SET_SIGN_IN_PRELOADER',
  SET_LIST_OF_USERS: 'SET_LIST_OF_USERS',
  SET_FORM_ERROR: 'SET_FORM_ERROR',
  RESTART_LISTEN_SERVER: 'RESTART_LISTEN_SERVER',
});

export default types;

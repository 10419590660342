import { createStyles } from '@material-ui/core/styles';
import jssVariables from "../../../helpers/variables";

const DashboardStyle = (theme: any) =>
  createStyles({
    content: {
      position: 'relative',
      minHeight: '100vh',
      flexGrow: 1,
      width: '100%',
      backgroundColor: jssVariables.baseColor,
    },
    title: {
      marginTop: 20,
      marginBottom: 0,
      fontSize: 28,
      color: '#000'
    },
  });

export default DashboardStyle;

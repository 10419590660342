import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import SwiperCore, { Autoplay, EffectFade, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import MainSliderStyle from './MainSliderStyle';
import imgDemo1 from "./img/slide1.jpg"
import imgDemo2 from "./img/slide2.jpg"
import imgDemo3 from "./img/slide3.jpg"

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

interface MainSliderProps {
  classes: any;
}

SwiperCore.use([Autoplay, Pagination, EffectFade]);

const MainSlider: React.FC<MainSliderProps> = ({ classes }) => {
  const slidesContent = [
    {
      categoryTitle: 'Премиум душевые кабины',
      title: 'Погружение в стиль. Душевая кабина "Зевс"',
      description: 'Душа моя озарена неземной радостью, как эти чудесные весенние утра, которыми я наслаждаюсь от всего сердца.',
      img: imgDemo1,
    },
    {
      categoryTitle: 'Премиум душевые кабины',
      title: 'Исключительно для наслаждения. "Франческа"',
      description: 'Душа моя озарена неземной радостью, как эти чудесные весенние утра, которыми я наслаждаюсь от всего сердца.',
      img: imgDemo2,
    },
    {
      categoryTitle: 'Премиум душевые кабины',
      title: 'Удовольствие со всех сторон. "Пантеон"',
      description: 'Душа моя озарена неземной радостью, как эти чудесные весенние утра, которыми я наслаждаюсь от всего сердца.',
      img: imgDemo3,
    },
  ];

  const slides = slidesContent.map((slide, index) => (
    <SwiperSlide key={`slide is ${slide.title + index}`} className={classes.sliderSlide}>
      <div className={classes.mainSlide}>
        <Container fixed>
          <p className={classes.categoryTitle}>
            {slide.categoryTitle}
          </p>
          <h2 className={classes.mainTitle}>
            {slide.title}
          </h2>
          <p className={classes.description}>
            {slide.description}
          </p>
          <div className={classes.imageWrapper}>
            <img className={classes.image} src={slide.img} alt="" />
          </div>
        </Container>
      </div>
    </SwiperSlide>
  ));

  return (
    <div className={classes.sliderWrapper}>
      <Swiper
        autoplay
        slidesPerView={1}
        effect="fade"
        pagination={{ clickable: true }}
      >
        {slides}
      </Swiper>
    </div>
  )
};

export default withStyles(MainSliderStyle, {
  name: 'MainSlider',
})(MainSlider);
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import AppBarUI from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import AppBarStyle from './AppBarStyle';
import CustomButton from "../../ui/CustomButton/CustomButton";
import {RootState} from "../../../../engine/init/rootReducer";
import uiActions from "../../../../engine/core/ui/action";

interface AppBarProps {
  classes: any;
  liveChatModalStatus: boolean;
  signInModalStatus: boolean;
  setLiveChatModalStatus: (status: boolean) => object;
  setSignInModalStatus: (preloader: boolean) => object;
}

type AppBarDispatchToProps = {
  setSignInModalStatus: (preloader: boolean) => object;
  setLiveChatModalStatus: (status: boolean) => object;
};

const mapStateToProps = (state: RootState) => ({
  liveChatModalStatus: state.ui.get('liveChatModalStatus'),
  signInModalStatus: state.ui.get('signInModalStatus'),
});

const mapDispatchToProps: AppBarDispatchToProps = {
  setLiveChatModalStatus: uiActions.setLiveChatModalStatus,
  setSignInModalStatus: uiActions.setSignInModalStatus,
};

class AppBar extends React.PureComponent<AppBarProps> {
  menuLinks = {
    catalog: {
      ru: 'Каталог',
      link: '/catalog',
    },
    about: {
      ru: 'О Компании',
      link: '/about',
    },
    blog: {
      ru: 'Блог',
      link: '/blog',
    },
    contacts: {
      ru: 'Контакты',
      link: '/contacts',
    },
  };

  state = {
    menuOpen: false,
    movePage: false,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.headerScrollHandler)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.headerScrollHandler)
  }

  headerScrollHandler = () => {
    if (window.scrollY <= 115) this.setState({ movePage: false });
    else this.setState({ movePage: true });
  };

  openMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen })
  };

  closeMenu = () => {
    this.setState({ menuOpen: false })
  };

  openLiveChatModal = () => {
    const { setLiveChatModalStatus, liveChatModalStatus } = this.props;
    setLiveChatModalStatus(!liveChatModalStatus);
  };

  openSignInModal = () => {
    const { setSignInModalStatus, signInModalStatus } = this.props;
    setSignInModalStatus(!signInModalStatus);
  };

  render() {
    const { classes } = this.props;
    
    const links = Object.values(this.menuLinks).map(item => (
      <li className={classes.menuItem} key={item.link}>
        <NavLink to={item.link} onClick={this.closeMenu}>
          {item.ru}
        </NavLink>
      </li>
    ));

    return (
      <AppBarUI className={`${classes.appbar} ${this.state.movePage ? classes.moved : ''}`} elevation={0}>
        <Container fixed className={classes.toolbar}>
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={4} sm={3} md={2}>
              <NavLink to="/" className={classes.logo} onClick={this.closeMenu}>
                Danavis
              </NavLink>
            </Grid>

            <Grid item xs={7} sm={8} md={10} >
              <div className={`${classes.menuWrapper} ${this.state.menuOpen ? classes.menuOpened : ''}`} >
                <ul className={classes.menu} >
                  {links}
                </ul>

                <div className={classes.buttonRow}>
                  <CustomButton customClass={`${classes.buttonDefault} ${classes.buttonChat}`} handleClick={this.openLiveChatModal}>
                    <FontAwesomeIcon icon={faComments} />Связаться с нами
                  </CustomButton>
                  <CustomButton customClass={classes.buttonDefault} handleClick={this.openSignInModal}>
                    Войти
                  </CustomButton>
                </div>
              </div>

            </Grid>

            <Grid item xs={1} >
              <button className={`${classes.hamburger} ${this.state.menuOpen ? classes.hamburgerClose : ''}`} onClick={this.openMenu} onKeyDown={this.openMenu}>
                <span className={classes.hamburgerLine} />
                <span className={classes.hamburgerLine} />
                <span className={classes.hamburgerLine} />
              </button>
            </Grid>

          </Grid>
        </Container>
      </AppBarUI>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(AppBarStyle, {
  name: 'AppBar',
})(AppBar));
import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Accordion, Divider, ListItem, ListItemIcon, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { Dashboard, ExpandMore, Assignment, ShoppingCart, People } from '@material-ui/icons';
import SidebarStyle from './SidebarStyle';
import BadgeInfo from '../BadgeInfo/BadgeInfo';

interface SidebarProps {
  classes: any;
}

const Sidebar: React.FC<SidebarProps> = ({ classes }) => {

  return (
    <div className={classes.sidebar}>
      <h1 className={classes.logo}>Danavis</h1>
      {/* <BadgeInfo */}
      {/*  name='Admin' */}
      {/*  status='moderator' */}
      {/* /> */}
      <Divider className={classes.divider} />
      <h4 className={classes.sectionTitle}>Основное</h4>
      <Accordion expanded className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <span>Статистика</span>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionWrapper}>
          <ListItem button component={Link} to="/admin/dashboard" >
            <ListItemIcon>
              <Dashboard />
            </ListItemIcon>
            <p>Статистика</p>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <ShoppingCart />
            </ListItemIcon>
            <p>Заказы</p>
          </ListItem>
          <ListItem button component={Link} to="/admin/users">
            <ListItemIcon>
              <People />
            </ListItemIcon>
            <p>Пользователи</p>
          </ListItem>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion> */}
      {/*  <AccordionSummary */}
      {/*    expandIcon={<ExpandMore />} */}
      {/*    aria-controls="panel1a-content" */}
      {/*    id="panel1a-header" */}
      {/*  > */}
      {/*    <span>Продажи</span> */}
      {/*  </AccordionSummary> */}
      {/*  <AccordionDetails className={classes.accordionWrapper}> */}
      {/*    <ListItem button> */}
      {/*      <ListItemIcon> */}
      {/*        <Assignment /> */}
      {/*      </ListItemIcon> */}
      {/*      <p>Текущий месяц</p> */}
      {/*    </ListItem> */}
      {/*    <ListItem button> */}
      {/*      <ListItemIcon> */}
      {/*        <Assignment /> */}
      {/*      </ListItemIcon> */}
      {/*      <p>Последний квартал</p> */}
      {/*    </ListItem> */}
      {/*    <ListItem button> */}
      {/*      <ListItemIcon> */}
      {/*        <Assignment /> */}
      {/*      </ListItemIcon> */}
      {/*      <p>Year-end sale</p> */}
      {/*    </ListItem> */}
      {/*  </AccordionDetails> */}
      {/* </Accordion> */}

      {/* <Divider className={classes.divider} /> */}
      {/* <h4 className={classes.sectionTitle}>Additional</h4> */}
      {/* <Accordion> */}
      {/*  <AccordionSummary */}
      {/*    expandIcon={<ExpandMore />} */}
      {/*    aria-controls="panel1a-content" */}
      {/*    id="panel1a-header" */}
      {/*  > */}
      {/*    <span>Продажи</span> */}
      {/*  </AccordionSummary> */}
      {/*  <AccordionDetails className={classes.accordionWrapper}> */}
      {/*    <ListItem button> */}
      {/*      <ListItemIcon> */}
      {/*        <Assignment /> */}
      {/*      </ListItemIcon> */}
      {/*      <p>Текущий месяц</p> */}
      {/*    </ListItem> */}
      {/*    <ListItem button> */}
      {/*      <ListItemIcon> */}
      {/*        <Assignment /> */}
      {/*      </ListItemIcon> */}
      {/*      <p>Последний квартал</p> */}
      {/*    </ListItem> */}
      {/*    <ListItem button> */}
      {/*      <ListItemIcon> */}
      {/*        <Assignment /> */}
      {/*      </ListItemIcon> */}
      {/*      <p>Year-end sale</p> */}
      {/*    </ListItem> */}
      {/*  </AccordionDetails> */}
      {/* </Accordion> */}
      {/* <Accordion> */}
      {/*  <AccordionSummary */}
      {/*    expandIcon={<ExpandMore />} */}
      {/*    aria-controls="panel1a-content" */}
      {/*    id="panel1a-header" */}
      {/*  > */}
      {/*    <span>Продажи</span> */}
      {/*  </AccordionSummary> */}
      {/*  <AccordionDetails className={classes.accordionWrapper}> */}
      {/*    <ListItem button> */}
      {/*      <ListItemIcon> */}
      {/*        <Assignment /> */}
      {/*      </ListItemIcon> */}
      {/*      <p>Текущий месяц</p> */}
      {/*    </ListItem> */}
      {/*    <ListItem button> */}
      {/*      <ListItemIcon> */}
      {/*        <Assignment /> */}
      {/*      </ListItemIcon> */}
      {/*      <p>Последний квартал</p> */}
      {/*    </ListItem> */}
      {/*    <ListItem button> */}
      {/*      <ListItemIcon> */}
      {/*        <Assignment /> */}
      {/*      </ListItemIcon> */}
      {/*      <p>Year-end sale</p> */}
      {/*    </ListItem> */}
      {/*  </AccordionDetails> */}
      {/* </Accordion> */}

    </div>
  )

};

export default withStyles(SidebarStyle, {
  name: 'Sidebar'
})(Sidebar);

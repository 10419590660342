import { createStyles } from '@material-ui/core/styles';
// import jssVariables from '../../../../helpers/variables';

const ProductsStyle = (theme: any) =>
  createStyles({
    container: {
      marginTop: 40,
    },
    products: {
      marginTop: 25,
      marginBottom: 100,
    },
  });

export default ProductsStyle;

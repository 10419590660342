import asyncTypes from './asyncTypes';

const userActionsAsync = Object.freeze({
  signUpAsync: (userData: any) => ({
    type: asyncTypes.SIGN_UP_ASYNC,
    payload: userData
  }),
  signInAsync: (tokenBase64: string) => ({
    type: asyncTypes.SIGN_IN_ASYNC,
    payload: tokenBase64
  }),
  updateTokenAsync: (token: string) => ({
    type: asyncTypes.UPDATE_TOKEN_ASYNC,
    payload: token
  }),
  signOutAsync: () => ({
    type: asyncTypes.SIGN_OUT_ASYNC
  }),
  getUsersAsync: (token: string) => ({
    type: asyncTypes.GET_USERS_ASYNC,
    payload: token
  }),
  updateUserAsync: (data: object) => ({
    type: asyncTypes.UPDATE_USER_ASYNC,
    payload: data
  }),
});

export default userActionsAsync;

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AdminProfileStyle from './AdminProfileStyle';


interface AdminProfileProps {
  classes: any
}

const AdminProfile: React.FC<AdminProfileProps> = ({ classes }) => {
  return (
    <div>
      profile
    </div>
  )
};

export default withStyles(AdminProfileStyle, {
  name: 'AdminProfile'
})(AdminProfile);
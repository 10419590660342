import { createStyles } from '@material-ui/core/styles';

const GlobalPreloaderStyle = (theme: any) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 3,
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      width: 'max-content',
      height: 'max-content',
      zIndex: 999999,
      borderRadius: '50%',
      boxShadow: '0 0 10px rgb(0 0 0 / 10%)',
      backgroundColor: '#fff',
      visibility: 'hidden',
      opacity: 0,
      transition: 'transform .3s, visibility .3s, opacity .3s',
    },
    active: {
      transform: 'translateY(20px)',
      visibility: 'visible',
      opacity: 1,
    },
    bottom: {
      color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    top: {
      color: '#1a90ff',
      animationDuration: '550ms',
      position: 'absolute',
      left: 3,
    },
    circle: {
      strokeLinecap: 'round',
    },
  });

export default GlobalPreloaderStyle;
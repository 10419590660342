import React, {useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import CategoriesStyle from './CategoriesStyle';
import Category from "../../ui/Category/Category";
import ShowerImg from './img/shower.jpg';

interface CategoriesProps {
  classes: any;
}

const Categories: React.FC<CategoriesProps> = ({ classes }) => {
  const [hoverItem, setHoverItem] = useState(null);


  const hoverEffect = (id: any) => {
    if (id !== hoverItem) setHoverItem(id);
  };

  const hoverEffectOut = () => {
    if (hoverItem !== null) setHoverItem(null);
  };

  const cards = [
    {
      id: 0,
      categoryTitle: 'Премиум душевые кабины',
      title: 'Раздвижные душевые кабины',
      description: 'Душа моя озарена неземной радостью, как эти чудесные весенние утра, которыми я наслаждаюсь от всего сердца.',
      img: ShowerImg,
    },
    {
      id: 1,
      categoryTitle: 'Премиум душевые кабины',
      title: 'Распашные душевые кабины',
      description: 'Душа моя озарена неземной радостью, как эти чудесные весенние утра, которыми я наслаждаюсь от всего сердца.',
      img: ShowerImg,
    },
    {
      id: 2,
      categoryTitle: 'Премиум душевые кабины',
      title: 'Душевые перегородки',
      description: 'Душа моя озарена неземной радостью, как эти чудесные весенние утра, которыми я наслаждаюсь от всего сердца.',
      img: ShowerImg,
    },
    {
      id: 3,
      categoryTitle: 'Премиум душевые кабины',
      title: 'Душевые перегородки',
      description: 'Душа моя озарена неземной радостью, как эти чудесные весенние утра, которыми я наслаждаюсь от всего сердца.',
      img: ShowerImg,
    },
  ];

  return (
    <Container fixed className={classes.container}>
      <h2>Категории</h2>
      <Grid container spacing={2} className={classes.categories}>
        {cards.map(item => <Category
          card={item}
          hoverItem={hoverItem}
          hoverEffect={hoverEffect}
          hoverEffectOut={hoverEffectOut}
          key={item.id} />)}
      </Grid>
    </Container>
  )
};

export default withStyles(CategoriesStyle, {
  name: 'Categories',
})(Categories);
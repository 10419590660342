import { all, takeEvery } from 'redux-saga/effects';
import asyncTypes from './asyncTypes';
import callSignUpWorker from './workers/signUp';
import callSignInWorker from './workers/signIn';
import callSignOutWorker from './workers/signOut';
import callGetUsersWorker from './workers/getUsers';
import callUpdateTokenWorker from './workers/updateToken';
import callUpdateUserWorker from './workers/updateUser';

function* watchSignUp() {
  yield takeEvery(asyncTypes.SIGN_UP_ASYNC, callSignUpWorker);
}

function* watchSignIn() {
  yield takeEvery(asyncTypes.SIGN_IN_ASYNC, callSignInWorker);
}

function* watchSignOut() {
  yield takeEvery(asyncTypes.SIGN_OUT_ASYNC, callSignOutWorker);
}

function* watchUpdateToken() {
  yield takeEvery(asyncTypes.UPDATE_TOKEN_ASYNC, callUpdateTokenWorker);
}

function* watchGetUsers() {
  yield takeEvery(asyncTypes.GET_USERS_ASYNC, callGetUsersWorker);
}

function* watchUpdateUser() {
  yield takeEvery(asyncTypes.UPDATE_USER_ASYNC, callUpdateUserWorker);
}

export default function* watchersUser() {
  yield all([
    watchSignUp(),
    watchSignIn(),
    watchSignOut(),
    watchGetUsers(),
    watchUpdateToken(),
    watchUpdateUser()
  ]);
}

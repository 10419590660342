import { createStyles } from '@material-ui/core';
import jssVariables from '../../../../helpers/variables';

const LiveChatStyle = () =>
  createStyles({
    modal: {
      position: 'absolute',
      width: 720,
      borderRadius: 15,
      overflow: 'hidden',
      backgroundColor: jssVariables.baseColor,
    },
    modalWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
    },
    overlay: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 9999,
    },
    overlayClose: {
      backgroundColor: 'rgba(0,0,0,.4)',
      width: '100%',
      height: '100%',
    },
    close: {
      position: 'absolute',
      top: 24,
      right: 24,
      width: 24,
      height: 24,
      border: 'none',
      padding: 0,
      backgroundColor: 'transparent',
      cursor: 'pointer',
      '&::before': {
        content: "''",
        position: 'absolute',
        top: 0,
        left: 11,
        width: 2,
        height: 24,
        backgroundColor: '#000',
        transform: 'rotate(-45deg)',
      },
      '&::after': {
        content: "''",
        position: 'absolute',
        top: 0,
        left: 11,
        width: 2,
        height: 24,
        backgroundColor: '#000',
        transform: 'rotate(45deg)',
      }
    },
    modalLeft: {
      width: '30%',
      height: '100%',
    },
    modalRight: {
      width: '70%',
      height: '100%',
      paddingLeft: 15,
      paddingRight: 70,
      borderLeft: '1px solid #ececec',
    },
    title: {
      fontSize: 20,
      fontWeight: 600,
      marginTop: 22,
    },
    chat: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: 350,
      paddingBottom: 15,
      paddingTop: 15,
    },
    chatArea: {
      flexGrow: 1,
      maxHeight: 418,
      overflowY: 'auto',
      overflowX: 'hidden',
      paddingRight: 5,
      listStyleType: 'none',
      '&::-webkit-scrollbar': {
        width: 5,
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#f8f8f8',
        borderRadius: 40,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#ccc',
        borderRadius: 40,
      },
    },
    chatField: {
      display: 'flex',
      alignItems: 'center',
      padding: '6px 15px',
      fontSize: 14,
      fontWeight: 500,
      width: '100%',
      height: 35,
      borderRadius: 40,
      overflow: 'hidden',
      whiteSpace: 'pre-wrap',
      border: '1px solid #ececec',
      transition: 'box-shadow .3s',
      '&:focus': {
        boxShadow: '0 0 5px rgb(0, 102, 255, 0.2)',
        outline: 'none',
      },
      '&:empty::before': {
        content: 'attr(data-placeholder)',
        cursor: 'text'
      },
    },
    chatMessage: {
      fontSize: 14,
      fontWeight: 500,
      padding: '3px 8px',
      color: jssVariables.fontColor,
      width: 'max-content',
      backgroundColor: '#ececec',
      borderRadius: 15,
      marginBottom: 3,
      maxWidth: 300,
      overflowWrap: 'break-word',
    },
    chatMessageSelf: {
      backgroundColor: jssVariables.firstBrandColor,
      color: jssVariables.baseColor,
      marginLeft: 'auto'
    },
    chatAnim: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '3px 8px',
      height: 26,
      color: jssVariables.fontColor,
      width: 'max-content',
      backgroundColor: '#ececec',
      borderRadius: 15,
      marginBottom: 3,
      maxWidth: 300,
      '& span': {
        width: 4,
        height: 4,
        backgroundColor: '#999',
        borderRadius: '50%',
        transform: 'translateY(2px)',
        animation: '$botMessagesPreloader 1s ease-in-out infinite',
        '&:not(:last-of-type)': {
          marginRight: 5,
        },
        '&:nth-of-type(2)': {
          animationDelay: '0.15s',
        },
        '&:nth-of-type(3)': {
          animationDelay: '0.3s',
        }
      }
    },
    '@keyframes botMessagesPreloader': {
      '0%, 100%': {
        transform: 'translateY(2px)',
      },
      '50%': {
        transform: 'translateY(-2px)',
      },
    },
  });

export default LiveChatStyle;
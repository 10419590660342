import React from 'react';
import {connect} from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';
import Chart from 'react-apexcharts';
import {Redirect} from "react-router-dom";
import DashboardStyle from './DashboardStyle';
import {RootState} from "../../../../../engine/init/rootReducer";

interface DashboardProps {
  classes: any;
  userInfo: any;
}

const mapStateToProps = (state: RootState) => ({
  userInfo: state.user.get('userInfo'),
});

class Dashboard extends React.PureComponent<DashboardProps> {
  render() {
    const { classes, userInfo } = this.props;

    const miniChart = {
      options: {
        chart: {
          id: 'basic-bar',
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
          ],
          labels: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          }
        },
      },
      series: [
        {
          name: 'series-1',
          data: [30, 40, 45, 50, 49, 60, 70, 91],
        },
      ]
    };

    return userInfo !== null && userInfo.token && userInfo.role === 'admin' ? (
      <div className={classes.adminDashboard}>
        <main className={classes.content}>
          <div className={classes.header}>
            <h1 className={classes.title}>Статистика</h1>
          </div>
          <Grid container spacing={3} className={classes.container}>
            <Grid item xs={12} md={6} lg={4}>
              <Paper className={classes.fixedPaper} elevation={3}>
                <h3>Продано товаров</h3>
                <Chart
                  options={miniChart.options}
                  series={miniChart.series}
                  type="line"
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Paper className={classes.fixedPaper} elevation={3}>
                <h3>Итого продано</h3>
                <Chart
                  options={miniChart.options}
                  series={miniChart.series}
                  type="line"
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Paper className={classes.fixedPaper} elevation={3}>
                <h3>Всего Прибыль</h3>
                <Chart
                  options={miniChart.options}
                  series={miniChart.series}
                  type="line"
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} className={classes.fixedPaper}>
                <Chart
                  options={miniChart.options}
                  series={miniChart.series}
                  type="area"
                  width='100%'
                  height={300}
                />
              </Paper>
            </Grid>
          </Grid>
        </main>
      </div>
    ) : <Redirect to="/" />;
  }
}

export default connect(
  mapStateToProps,
  null
)(withStyles(DashboardStyle, {
  name: 'Dashboard'
})(Dashboard)
);

import { createStyles } from '@material-ui/core';
import jssVariables from '../../../../helpers/variables';

const AppBarStyle = (theme: any) =>
  createStyles({
    appbar: {
      backgroundColor: 'transparent',
      transition: 'background-color .2s'
    },
    moved: {
      backgroundColor: jssVariables.baseColor,
      '& $toolbar': {
        height: 65,
      }
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      minHeight: 65,
      height: 100,
      transition: 'height .3s',
    },
    button: {
      width: 'auto',
      paddingLeft: 25,
      paddingRight: 25,
    },
    buttonChat: {
      marginRight: 10,
      backgroundColor: '#2bd6ab !important',
      '& svg': {
        marginRight: 10,
      },
    },
    buttonDefault: {
      width: 'auto',
      padding: "15px 25px",
      backgroundColor: jssVariables.firstBrandColor,
      backgroundImage: 'none',
      color: '#fff',
      textTransform: 'none',
      fontSize: 15,
      '&:hover': {
        backgroundColor: jssVariables.hoveredFirstBrandColor,
      }
    },
    logo: {
      fontSize: 36,
      fontWeight: 700,
      color: jssVariables.textColor,
      position: 'relative',
      zIndex: 1000,
    },
    menu: {
      listStyle: 'none',
      display: 'flex',
      '& li': {
        fontSize: 16,
        fontWeight: 700,
        color: jssVariables.textColor,
        '&:not(:last-child)': {
          marginRight: 35,
        },
        '& a': {
          color: 'inherit',
        },
      }
    },
    menuWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    hamburger: {
      display: 'none',
      border: 'none',
      background: 'none',
      padding: 0,
      outline: 'none',
      width: 25,
      height: 13,
      transform: 'scaleY(1)',
      transition: 'all .5s',
      cursor: 'pointer',
      '& span': {
        display: 'block',
        width: '100%',
        height: 3,
        backgroundColor: '#000',
        transition: 'all .5s',
      },
      '& span:not(:last-child)': {
        marginBottom: 3,
      }
    },
    hamburgerClose: {
      transform: 'scaleY(-1) rotate(-90deg)',
      height: 25,

      '& span:first-child': {
        transform: 'rotate(45deg)',
        marginTop: 11,
      },
      '& span:nth-of-type(2)': {
        transform: 'scale(0)',
        opacity: 0,
      },
      '& span:last-child': {
        transform: 'rotate(-45deg)',
        marginTop: -12,
      },
    },
    '@media (max-width: 959px)': {
      hamburger: {
        display: 'block',
      },
      menuWrapper: {
        position: 'absolute',
        display: 'flex',
        height: '100vh',
        width: '100vw',
        borderRadius: '0 0 0 1000px',
        flexDirection: 'column',
        justifyContent: 'center',
        top: '-100vh',
        right: '-100vw',
        backdropFilter: 'blur(4px)',
        backgroundColor: 'rgba(236, 238, 239, .6)',
        transition: 'all 1s ease',
      },
      menuOpened: {
        top: 0,
        right: 0,
        height: '200vh',
        width: '200vw',
        paddingLeft: '100vw',
        paddingBottom: '100vh',
        borderRadius: '0px',
      },
      buttonDefault: {
        fontSize: 16,
        padding: '15px 25px',
        marginLeft: 0,
        marginRight: 0,
      },
      menu: {
        flexDirection: 'column',
        marginBottom: 10,
        '& li': {
          textAlign: 'center',
          fontSize: 26,
          marginBottom: 15,
          '&:not(:last-child)': {
            marginRight: 0,
          },
          '& a': {
            color: 'inherit',
          },
        }
      },
    }
  });

export default AppBarStyle;

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router/immutable";
import * as serviceWorker from './serviceWorker';

import Application from './renderer/ui/containers/Application/Application';
import store, { history } from './renderer/engine/init/store';
import './renderer/helpers/root.scss';
import './renderer/helpers/fonts/Circe/style.scss';

// Create main element
const mainElement = document.createElement('div');
document.body.appendChild(mainElement);

// Render components
const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <BrowserRouter>
          <Application />
        </BrowserRouter>
      </ConnectedRouter>
    </Provider>,
    mainElement
  );
};

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
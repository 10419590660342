import React from 'react';
import { Paginator } from 'primereact/paginator';

interface TableDataHocState {
  rows: any;
}

export default function TableDataHoc(
  HocComponent: React.FC<any>,
  data: any,
  updateUserAsync: (data: object) => void, 
  setGlobalPreloaderStatus: (status: boolean) => void,
  classes: any
) {
  return class extends React.Component<{}, TableDataHocState> {
    originalRows: any = {};

    constructor(props: any) {
      super(props);
      this.state = {
        rows: [...data]
      };
    }

    onRowEditInit = (event: any) => {
      this.originalRows[event.index] = { ...this.state.rows[event.index] };
    };

    onRowEditSave = (event: any) => {
      setGlobalPreloaderStatus(true);
      updateUserAsync(event.data);
    };

    onRowEditCancel = (event: any) => {
      const tableRows = [...this.state.rows];
      tableRows[event.index] = this.originalRows[event.index];
      delete this.originalRows[event.index];

      this.setState({ rows: tableRows });
    };

    onEditorValueChange = (props: any, value: any) => {
      const updatedProducts = [...props.value];
      updatedProducts[props.rowIndex][props.field] = value;
      this.setState({ rows: updatedProducts });
    };

    onPageChange = (event: any) => {
      console.log(event);
    };

    render() {
      const { rows } = this.state;
      return (
        <>
          <HocComponent
            classes={classes}
            rows={rows}
            onRowEditInit={this.onRowEditInit}
            onRowEditCancel={this.onRowEditCancel}
            onRowEditSave={this.onRowEditSave}
            onEditorValueChange={this.onEditorValueChange}
          />
          <Paginator first={1} rows={rows.length} onPageChange={this.onPageChange} />
        </>
      )
    }
  }
}
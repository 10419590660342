import { text } from '@fortawesome/fontawesome-svg-core';
import { createStyles } from '@material-ui/core/styles';
import jssVariables from '../../../../helpers/variables';

const MainSliderStyle = (theme: any) =>
  createStyles({
    mainSlide: {
      padding: '200px 0',
      [theme.breakpoints.up('md')]: {
        paddingTop: 300,
        paddingBottom: 200,
      }
    },
    sliderSlide: {
      backgroundColor: jssVariables.baseColor,
    },
    categoryTitle: {
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 700,
      width: 'max-content',
      backgroundImage: 'linear-gradient(to left, #3e89cb 0%, #9d4a91 35%, #d47730 67%, #d47730 100%);',
      backgroundClip: 'text',
      '-webkit-background-clip': 'text',
      color: 'transparent',
      textShadow: '0 0 1px rgb(0 0 0 / 20%)',
      marginBottom: 20,
      [theme.breakpoints.up('sm')]: {
        textShadow: 'none',
        backgroundImage: 'linear-gradient(to right, #3e89cb 0%, #9d4a91 35%, #d47730 67%, #d47730 100%);',
      },
    },
    mainTitle: {
      fontSize: 28,
      fontWeight: 700,
      lineHeight: 1.1,
      marginBottom: 20,
      maxWidth: 500,
      [theme.breakpoints.up('sm')]: {
        fontSize: 40,
        lineHeight: 1,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 52,
        maxWidth: 600,
        lineHeight: 1.2,
      }
    },
    description: {
      color: jssVariables.darkGreyColor,
      fontSize: 15,
      fontWeight: 600,
      maxWidth: 300,
      [theme.breakpoints.up('md')]: {
        fontSize: 17,
        maxWidth: 500,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 18,
        maxWidth: 600,
      }
    },
    imageWrapper: {
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: -1,
      width: '100%',
      height: '100%',
      [theme.breakpoints.up('md')]: {
        width: '60%',
      },
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
      '&:before': {
        content: '""',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundImage: 'linear-gradient(rgba(255, 255, 255) 0%, #fff0 65%), linear-gradient(rgba(255, 255, 255, 0) 33%, #fff 100%)',
        [theme.breakpoints.up('sm')]: {
          backgroundImage: 'radial-gradient(farthest-corner at 72% 15%, rgba(255, 255, 255, .2) 30%, #fff 70%), radial-gradient(farthest-corner at 69% 14%, rgba(255, 255, 255, 0.1) 63%, #fff 90%)',
        },
      }
    },
    sliderWrapper: {
      '& .swiper-pagination-bullet': {
        height: 3,
        width: '30px',
        borderRadius: '3px',
        background: '#000',
        opacity: 0.1,
        transition: 'all 0.3s ease',
      },
      '& .swiper-pagination-bullet.swiper-pagination-bullet-active': {
        width: '120px',
        opacity: 0.3,
      },
    },
  });

export default MainSliderStyle;

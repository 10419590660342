import { Map, set } from 'immutable';
import types from './types';

export interface UiState extends Map<string, any> {
  readonly signUpModalStatus?: boolean;
  readonly signInModalStatus?: boolean;
  readonly liveChatModalStatus?: boolean;
  readonly botMessagePreloaderStatus?: boolean;
  readonly globalPreloaderStatus?: boolean;
}

const initialState: UiState = Map({
  signUpModalStatus: false,
  signInModalStatus: false,
  liveChatModalStatus: false,
  botMessagePreloaderStatus: true,
  globalPreloaderStatus: false,
});

export const uiReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.SIGN_UP_MODAL_STATUS:
      return set(state, 'signUpModalStatus', action.payload);
    case types.SIGN_IN_MODAL_STATUS:
      return set(state, 'signInModalStatus', action.payload);
    case types.LIVE_CHAT_MODAL_STATUS:
      return set(state, 'liveChatModalStatus', action.payload);
    case types.BOT_MESSAGES_PRELOADER_STATUS:
      return set(state, 'botMessagePreloaderStatus', action.payload);
    case types.GLOBAL_PRELOADER_STATUS:
      return set(state, 'globalPreloaderStatus', action.payload);
    default:
      return state;
  }
};

import { createStyles } from '@material-ui/core';
import jssVariables from "../../../../../helpers/variables";


const AdminAppBarStyle = (theme: any) =>
  createStyles({
    header: {
      padding: '5px 25px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: '#ececec',
      color: '#000',
      width: 'calc(100% - 280px)',
      boxShadow: 'none',
    },
    avatar: { 
      backgroundColor: jssVariables.firstBrandColor,
    },
    notificationIcon: {
      marginLeft: 'auto',
    },
    popUpWrapper: {
      top: 100,
    },
    popUpProfile: {
      padding: 15,
      paddingTop: 0,
      outline: 'none',
      '& h4': {
        width: 150,
      }
    },
    popUpLink: {
      fontFamily: 'inherit',
      color: '#222'
    },
    popUpIcon: {
      marginRight: 10,
    },
    buttonLogout: {
      width: 'calc(100% - 30px)',
      marginLeft: 15,
      marginTop: 10,
    },
    notificationMenu: {
      display: 'flex',
      flexDirection: 'column',
      '&:focus': {
        outline: 'none',
      },
      '& ul': {
        background: 'rgba(255, 255, 255, .3)',
      },
    },
    notificationTitle: {
      textAlign: 'center',
      fontSize: 15,
      fontWeight: 600,
      margin: '10px 0'
    },
  });

export default AdminAppBarStyle;

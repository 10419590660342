import {put, apply, StrictEffect} from 'redux-saga/effects';
import api from '../../../config/api';
import userActions from '../../action';

export default function* callUpdateTokenWorker({ payload: token }: any): Generator<StrictEffect> {
  const response: any = yield apply(api, api.user.updateToken, [token]);
  if (response.status === 200 && response.statusText === 'OK') {
    localStorage.setItem('userInfo', JSON.stringify(response.data));
    yield put(userActions.setUserInfo(response.data));
    window.location.reload();
  }
}

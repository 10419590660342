import React from 'react';
import {connect} from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import posed from "react-pose";
import {Redirect} from "react-router-dom";
import {RootState} from "../../../../../engine/init/rootReducer";
import LoginStyle from './LoginStyle';
import LoginForm from "./LoginForm";
import userActionsAsync from "../../../../../engine/core/user/saga/asyncActions";
import userActions from "../../../../../engine/core/user/action";

const Error = posed.div({
  show: {
    opacity:      1,
    scale:        1,
    transition:   { duration: 200 },
    applyAtStart: { display: 'flex' },
  },
  hide: {
    opacity:    0,
    scale:      0.7,
    transition: { duration: 200 },
    applyAtEnd: { display: 'none' },
  },
});

interface LoginProps {
  classes: any;
  errorText: string;
  signInPreloader: boolean;
  signInAsync: (tokenBase64: string) => object;
  setSignInPreloader: (status: boolean) => object;
  setFormError: (text: string) => object;
  userInfo: any;
}

type LoginDispatchToProps = {
  signInAsync: (tokenBase64: string) => object;
  setSignInPreloader: (status: boolean) => object;
};

const mapStateToProps = (state: RootState) => ({
  errorText: state.user.get('errorText'),
  signInPreloader: state.user.get('signInPreloader'),
  userInfo: state.user.get('userInfo')
});

const mapDispatchToProps: LoginDispatchToProps = {
  signInAsync: userActionsAsync.signInAsync,
  setSignInPreloader: userActions.setSignInPreloader,
};

class Login extends React.Component<LoginProps>{
  submitSignInForm = (data: any) => {
    const unlockedData = data.toJS();
    const tokenBase64 = btoa(`${unlockedData.email}:${unlockedData.password}`);
    this.props.setSignInPreloader(true);
    this.props.signInAsync(tokenBase64);
  };

  render() {
    const { classes, signInPreloader, errorText, userInfo } = this.props;

    return !userInfo.token && userInfo.role !== 'admin' ? (
      <div className={classes.authFormWrapper}>
        <div className={classes.left}>
          <div className={classes.wrapper}>
            {/* @ts-ignore */}
            <LoginForm classes={classes} onSubmit={this.submitSignInForm} signInPreloader={signInPreloader} />
            <Error className={classes.formError} pose={ errorText.length ? 'show' : 'hide'}>
              <div className={classes.formErrorX} />
              <span>{errorText}</span>
            </Error>
          </div>
        </div>
        <div className={classes.right}>
          <img src="https://app.jetadmin.io/v2.2.6/assets/images/background-auth.svg" alt="" />
        </div>
      </div>
    ) : <Redirect to="/admin/dashboard" />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(LoginStyle, {
  name: 'Login'
})(Login)
);
